import { PageHeader } from '@ant-design/pro-components';
import { Checkbox, Col, DatePicker, Divider, Form, Row, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import React from 'react';
import dayjs from 'dayjs';
import { ProjectPlayerResponseModel } from '@uniquegood/realworld-admin-interface';
import { ColumnsType } from 'antd/es/table';
import DefaultLayout from '@src/components/DefaultLayout';
import { projectApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { millisecondToTime } from '@src/utils/time';

export default function Participant() {
  const { id } = useParams();
  const accessToken = getLocalStorage('token');
  const queryData = new URLSearchParams(window.location.search);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [isFinishOnly, setIsFinishOnly] = React.useState(
    queryData.has('isFinishOnly') && queryData.get('isFinishOnly') === 'true'
      ? Boolean(queryData.get('isFinishOnly'))
      : false
  );
  const [date, setDate] = React.useState<string | null>(
    queryData.has('date') && queryData.get('date') !== 'null' ? queryData.get('date') : null
  );

  const participantList = useAsync(async () => {
    const { data } = await projectApi.attendeeList(id || '', currentPage, isFinishOnly, date || undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return data.data;
  }, [currentPage, isFinishOnly, date]);

  const columns: ColumnsType<ProjectPlayerResponseModel> = [
    {
      key: 'userId',
      dataIndex: 'userId',
      title: 'ID'
    },
    {
      key: 'userName',
      dataIndex: 'userName',
      title: '이름'
    },
    {
      key: 'startedAt',
      dataIndex: 'startedAt',
      title: '시작 시각',
      render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      title: '종료 시각',
      render: (value) => (value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '미종료')
    },
    {
      title: '플레이 타임',
      render: (value) => {
        return value.finishedAt !== null ? millisecondToTime(dayjs(value.finishedAt).diff(value.startedAt)) : '미종료';
      }
    }
  ];

  React.useEffect(() => {
    const handlePopState = () => {
      const queryData = new URLSearchParams(window.location.search);

      setIsFinishOnly(!!(queryData.has('isFinishOnly') && queryData.get('isFinishOnly') === 'true'));
      setDate(queryData.has('date') && queryData.get('date') !== 'null' ? queryData.get('date') : null);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <DefaultLayout>
      <PageHeader
        title="프로젝트 참여자 목록"
        subTitle="프로젝트 참여자 목록을 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Row gutter={[16, 16]}>
          <Form
            fields={[
              {
                name: 'isFinishOnly',
                value: isFinishOnly
              }
            ]}
            style={{ position: 'relative', width: '100%' }}
          >
            <Col span={24}>
              <Form.Item style={{ display: 'inline-block', width: '40%', marginRight: '16px' }}>
                <DatePicker.RangePicker
                  value={date ? [dayjs(date?.split('-')[0]), dayjs(date?.split('-')[1])] : null}
                  onChange={(value) => {
                    const dateToString = value
                      ? `${value?.[0]?.format('YYYY/MM/DD')}-${value?.[1]?.format('YYYY/MM/DD')}`
                      : null;
                    setDate(dateToString);
                    setCurrentPage(1);

                    window.history.pushState(undefined, '', `?date=${dateToString}&isFinishOnly=${isFinishOnly}`);
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item name="isFinishOnly" style={{ display: 'inline-block' }}>
                <Checkbox
                  checked={isFinishOnly}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setIsFinishOnly(e.target.checked);

                    window.history.pushState(undefined, '', `?date=${date}&isFinishOnly=${e.target.checked}`);
                  }}
                >
                  미종료 제외
                </Checkbox>
              </Form.Item>
            </Col>
          </Form>
          <Col span={24}>
            <Table
              dataSource={participantList.value?.data || []}
              columns={columns}
              loading={participantList.loading}
              rowKey="userId"
              pagination={{
                current: currentPage,
                total: participantList.value?.count,
                showSizeChanger: false,
                pageSize: 30,
                onChange: (page) => setCurrentPage(page)
              }}
            />
          </Col>
        </Row>
      </Content>
    </DefaultLayout>
  );
}
