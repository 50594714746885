import { Input, Modal, ModalProps, message } from 'antd';
import React from 'react';
import { fileApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface CreateFolderModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  refetch: () => unknown;
}

export default function CreateFolderModal({ modalData, closeModal, refetch }: CreateFolderModalProps) {
  const accessToken = getLocalStorage('token');

  const [input, setInput] = React.useState('');

  const handleSubmit = async () => {
    try {
      const modifiedInput = input.trim().replaceAll(' ', '_');

      const { data } = await fileApi.createDirectory(modifiedInput, undefined, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (data.success) {
        closeModal();
        await refetch();
        message.success('폴더 생성에 성공했습니다.');
      }
    } catch (e) {
      message.error('폴더 생성에 실패했습니다.');
      console.error(e);
    }
  };

  return (
    <Modal {...modalData} onOk={handleSubmit} okText="확인" cancelText="닫기">
      <Input value={input} onChange={(e) => setInput(e.target.value)} placeholder="폴더 이름" />
    </Modal>
  );
}
