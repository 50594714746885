import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import React from 'react';
import { HeaderThumbnailResponseModel } from '@uniquegood/realworld-admin-interface';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import DefaultLayout from '@src/components/DefaultLayout';
import { headerThumbnailApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import useModalState from '@src/hooks/useModalState';
import ThumbnailTable from './Table';
import CreateModal from './Modal/CreateModal';
import ConfirmDeleteModal from './Modal/ConfirmDeleteModal';
import ModifyModal from './Modal/ModifyModal';

export default function ThumbnailPage() {
  const { groupId } = useParams<{ groupId: string }>();

  const accessToken = getLocalStorage('token');

  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();
  const { openModal: openModifyModal, closeModal: closeModifyModal, modal: modifyModal } = useModalState();
  const { openModal: openConfirmModal, closeModal: closeConfirmModal, modal: confirmModal } = useModalState();

  const [currentSelectedData, setCurrentSelectedData] = React.useState<HeaderThumbnailResponseModel>();
  const [thumbnails, setThumbnails] = React.useState<HeaderThumbnailResponseModel[]>();

  const fetchThumbnailData = React.useCallback(async () => {
    try {
      const response = await headerThumbnailApi.getHeaderThumbnails(groupId || '', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      setThumbnails(response.data.data);

      return response.data.data;
    } catch (e) {
      console.error(e);

      return undefined;
    }
  }, [groupId, accessToken]);

  const handleCreateClick = () => {
    openCreateModal({});
  };

  const handleModifyClick = (data: HeaderThumbnailResponseModel) => {
    openModifyModal({});

    setCurrentSelectedData(data);
  };

  const handleDeleteClick = (id: string) => {
    openConfirmModal({
      onOk: async () => {
        try {
          if (thumbnails?.length === 1) {
            message.error('최소 1개의 썸네일은 유지되어야 합니다.');

            closeConfirmModal();

            return;
          }
          const { data } = await headerThumbnailApi.deleteHeaderThumbnail(groupId || '', id, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });

          if (data.success) {
            message.success('썸네일을 삭제했습니다.');

            closeConfirmModal();

            fetchThumbnailData();
          }
        } catch (e) {
          console.error(e);

          message.error('썸네일 삭제에 실패했습니다.');
        }
      }
    });
  };

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    const prevData = thumbnails;
    if (!thumbnails) return;

    try {
      if (active.id !== over?.id) {
        const activeIndex = thumbnails?.findIndex((record) => record.id === active?.id);
        const overIndex = thumbnails?.findIndex((record) => record.id === over?.id);
        const nextData = arrayMove(thumbnails, activeIndex, overIndex);

        setThumbnails(nextData);

        const activeItem = thumbnails[activeIndex];
        await headerThumbnailApi.editHeaderThumbnail(
          groupId || '',
          activeItem.id,
          {
            ...activeItem,
            order: overIndex + 1
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        message.success('섹션 순서를 변경했습니다.');

        fetchThumbnailData();
      }
    } catch (e) {
      console.error(e);

      message.error('섹션 순서 변경에 실패했습니다.');

      setThumbnails(prevData);
    }
  };

  React.useEffect(() => {
    fetchThumbnailData();
  }, [fetchThumbnailData]);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title="메인 썸네일 관리"
          subTitle="메인 페이지 상단의 썸네일 영역을 관리합니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
          <ControllerContainer>
            <Button onClick={handleCreateClick}>썸네일 생성</Button>
          </ControllerContainer>
          <ThumbnailTable
            data={thumbnails || []}
            onModifyClick={handleModifyClick}
            onDeleteClick={handleDeleteClick}
            onDragEnd={handleDragEnd}
          />
        </Content>
      </DefaultLayout>
      <CreateModal
        modalData={createModal}
        closeModal={closeCreateModal}
        groupId={groupId || ''}
        refetch={fetchThumbnailData}
      />
      <ModifyModal
        modalData={modifyModal}
        initialData={currentSelectedData}
        closeModal={closeModifyModal}
        groupId={groupId || ''}
        refetch={fetchThumbnailData}
      />
      <ConfirmDeleteModal modalData={confirmModal} />
    </>
  );
}

const ControllerContainer = styled.div`
  margin-bottom: 16px;
  text-align: right;
`;
