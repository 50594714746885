import { Col, Modal, ModalProps, Row, Select, notification } from 'antd';
import React from 'react';
import { projectApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface SetBadgeModalProps {
  modalData: ModalProps;
  selectedProjectId: string;
  closeModal: () => unknown;
}

export default function SetBadgeModal({ modalData, selectedProjectId, closeModal }: SetBadgeModalProps) {
  const accessToken = getLocalStorage('token');

  const [badge, setBadge] = React.useState('');

  const handleOkClick = async () => {
    try {
      const { data } = await projectApi.updateProjectBadge(
        selectedProjectId,
        {
          badge
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        notification.success({
          message: '배지 변경에 성공했습니다.'
        });
        closeModal();
      }
    } catch (e) {
      console.error(e);
      notification.error({
        message: '배지 변경에 실패했습니다. 다시 시도해주세요.'
      });
    }
  };

  return (
    <Modal {...modalData} title="배지 설정" transitionName="" okText="확인" cancelText="닫기" onOk={handleOkClick}>
      <Row gutter={[0, 8]}>
        <Col span={24}>hot / new / 배지 없음 중 선택해주세요.</Col>
        <Col span={24}>
          <Select
            options={[
              { value: 'hot', label: 'Hot' },
              { value: 'new', label: 'New' },
              { value: null, label: '배지 없음' }
            ]}
            onChange={setBadge}
            placeholder="선택해주세요."
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
    </Modal>
  );
}
