import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Dropdown, MenuProps, Pagination, Space, Table, Input, Tag, message, Form } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import { DownOutlined } from '@ant-design/icons';
import Modal from 'antd/es/modal/Modal';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { AuthenticationProviders } from '@uniquegood/realworld-admin-interface/dist';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { userApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import { PlayerDetailModal } from './PlayerDetailModal';
import { UserIdArea } from './UserIdArea';
import ResetModal from './ResetModal';

const { Search } = Input;

interface TableDataType {
  id: string;
  name: string;
  email: string;
  authInfo: string;
  createdAt: Date;
  isAdmin: boolean;
  point: {
    freePoint: number;
    paidPoint: number;
  };
}

interface MenuInfo {
  key: string;
  keyPath: string[];
  /** @deprecated This will not support in future. You should avoid to use this */
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

const items: MenuProps['items'] = [
  {
    label: '소유 채널',
    key: 'owned'
  },
  {
    label: '플레이 내역',
    key: 'playLog'
  },
  {
    label: '이용권 내역',
    key: 'usage'
  }
];

export default function ManageUser() {
  const navigate = useNavigate();
  const queryData = new URLSearchParams(window.location.search);

  const accessToken = getLocalStorage('token');

  const { openModal, closeModal, modal } = useModalState();
  const { openModal: openRemoveModal, closeModal: closeRemoveModal, modal: removeModal } = useModalState();
  const { openModal: openResetModal, closeModal: closeResetModal, modal: resetModal } = useModalState();

  const [searchKeyword, setSearchKeyword] = React.useState(queryData.get('search') || '');
  const [currentPage, setCurrentPage] = React.useState<number | undefined>(1);
  const [totalCount, setTotalCount] = React.useState<number | undefined>(undefined);
  const [selectedRow, setSelectedRow] = React.useState<TableDataType>();
  const [currentUserId, setCurrentUserId] = React.useState<string>();

  const [userData, fetchUserData] = useAsyncFn(async () => {
    const { data } = await userApi.getUsers('join', searchKeyword, currentPage, 30, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!data.data?.data) return [];

    setTotalCount(data.data.count);

    return data.data.data.map(
      (item, index) =>
        ({
          key: index,
          id: item.userId,
          handleId: item.handleId,
          name: item.userName,
          email: item.email,
          phoneNumber: item.phoneNumber,
          authInfo: item.providers,
          createdAt: dayjs(item.createdAt).format('YYYY.MM.DD HH:mm:ss'),
          isAdmin: item.isManager,
          point: {
            paidPoint: item.userRemainingPaidPoint,
            freePoint: item.userRemainingFreePoint
          },
          isCompletedPhoneCertification: item.isCompletedPhoneCertification
        } as unknown as TableDataType)
    );
  }, [searchKeyword, currentPage]);

  const handleMenuClick = ({ key }: { key: string }, row: TableDataType) => {
    navigate(`/user/${key}/${row.id}`);
  };

  const menuProps = (row: TableDataType) => {
    return {
      items,
      onClick: (e: MenuInfo) => handleMenuClick(e, row)
    };
  };

  const handleAdminClick = async (row: TableDataType) => {
    try {
      const accessToken = getLocalStorage('token');
      if (!row.isAdmin) {
        await userApi.promoteUser(row.id, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } else {
        await userApi.demoteUser(row.id, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      }

      fetchUserData();
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveClick = async (row: TableDataType) => {
    try {
      const { data } = await userApi.deleteUser(row.id || '', false, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (data.success) {
        await fetchUserData();
        closeRemoveModal();
        message.success('유저 탈퇴 처리에 성공했습니다.');
      } else {
        message.error('오류가 발생해 유저 탈퇴 처리에 실패했습니다.');
      }
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 400) {
          try {
            const { data } = await userApi.deleteUser(row.id, true, {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });

            if (data.success) {
              await fetchUserData();
              closeRemoveModal();
              message.success('유저 탈퇴 처리에 성공했습니다.');
            } else {
              message.error('오류가 발생해 유저 탈퇴 처리에 실패했습니다.');
            }
          } catch (e) {
            console.error(e);
            message.error('오류가 발생해 유저 탈퇴 처리에 실패했습니다.');
          }
        }
      } else {
        console.error(e);
        message.error('오류가 발생해 유저 탈퇴 처리에 실패했습니다.');
      }
    }
  };

  const handlePushClick = (row: TableDataType) => {
    navigate(pageKeyToPathnameFn[PageKey.PUSH_SEND]({ querystring: { userId: row.id } }));
  };

  const renderAuthInfo = (value: AuthenticationProviders) => {
    switch (value[0]) {
      case AuthenticationProviders.Anonymous:
        return <Tag>리얼월드 익명</Tag>;
      case AuthenticationProviders.Apple:
        return <Tag color="black">애플</Tag>;
      case AuthenticationProviders.Facebook:
        return <Tag color="#072a6c">페이스북</Tag>;
      case AuthenticationProviders.Kakao:
        return (
          <Tag color="#ffe812" style={{ color: '#59473f' }}>
            카카오
          </Tag>
        );
      case AuthenticationProviders.Naver:
        return <Tag color="#3eaf0e">네이버</Tag>;
      case AuthenticationProviders.Self:
        return <Tag color="#c869ff">리얼월드</Tag>;
      default:
        return <div />;
    }
  };

  const columns: ColumnsType<TableDataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      render: (value) => <UserIdArea value={value} />
    },
    {
      title: '고유 ID',
      dataIndex: 'handleId',
      key: 'handleId'
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value) => value || '-'
    },
    {
      title: '인증 정보',
      dataIndex: 'authInfo',
      key: 'authInfo',
      render: renderAuthInfo
    },
    {
      title: '가입일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => date.toString()
    },
    {
      title: '관리자 여부',
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      width: '100px',
      align: 'center',
      render: (isAdmin) => (isAdmin ? <Tag>관리자</Tag> : '')
    },
    {
      title: '본인인증 여부',
      dataIndex: 'isCompletedPhoneCertification',
      key: 'isCompletedPhoneCertification',
      render: (value) => (value === true ? <Tag color="green">인증 완료</Tag> : <Tag color="red">미인증</Tag>)
    },
    {
      title: '츄로',
      dataIndex: 'point',
      key: 'point',
      render: (point) => {
        return (
          <>
            <div style={{ cursor: 'pointer' }}>마이 츄로: {point.paidPoint}</div>
            <div style={{ cursor: 'pointer' }}>보너스 츄로: {point.freePoint}</div>
          </>
        );
      },
      onCell: (row) => {
        return {
          onClick: () => {
            setSelectedRow(row);
            openModal({
              title: `[${row.name}] 사용자의 츄로 조회`,
              children: <PlayerDetailModal userId={row.id} refetchFn={fetchUserData} />,
              okText: '확인',
              cancelText: '닫기'
            });
          }
        };
      }
    },
    {
      title: '동작',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, row) => {
        return (
          <>
            <Dropdown menu={menuProps(row)} trigger={['click']}>
              <Button size="small">
                <Space>
                  조회 메뉴
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Button
              onClick={() => handleAdminClick(row)}
              size="small"
              style={{ marginLeft: '8px', border: '1px solid #f7b924', color: '#f7b924' }}
            >
              {row.isAdmin ? '관리자 해제' : '관리자 등록'}
            </Button>
            <Button
              onClick={() => handlePushClick(row)}
              size="small"
              style={{ marginLeft: '8px', border: '1px solid #2955c8', color: '#2955c8' }}
            >
              푸시 보내기
            </Button>
            <Button
              onClick={() => {
                openResetModal({});
                setCurrentUserId(row.id);
              }}
              size="small"
              danger
              style={{ marginLeft: '8px' }}
            >
              인증 정보 초기화
            </Button>
            <Button
              onClick={() => {
                openRemoveModal({
                  title: '정말 삭제하시겠습니까?',
                  transitionName: '',
                  children: <div>[{row.name}]님의 데이터가 삭제됩니다.</div>,
                  onOk: () => handleRemoveClick(row),
                  okText: '확인',
                  cancelText: '취소'
                });
              }}
              size="small"
              danger
              style={{ marginLeft: '8px' }}
            >
              제거
            </Button>
          </>
        );
      }
    }
  ];

  React.useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  React.useEffect(() => {
    const handleSearchKeyword = () => {
      const queryData = new URLSearchParams(window.location.search);

      setSearchKeyword(queryData.get('search') || '');
    };

    window.addEventListener('popstate', handleSearchKeyword);

    return () => {
      window.removeEventListener('popstate', handleSearchKeyword);
    };
  }, []);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title="리얼월드 플레이어 관리"
          subTitle="리얼월드 플레이어를 관리합니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />

        <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
          <Form
            fields={[
              {
                name: ['search'],
                value: new URLSearchParams(window.location.search).get('search')
              }
            ]}
          >
            <Form.Item name="search">
              <Search
                id="searchBar"
                onSearch={(value) => {
                  setCurrentPage(1);
                  setSearchKeyword(value);

                  window.history.pushState(null, '', `/user?search=${value}`);
                }}
                enterButton
                style={{ marginBottom: '16px', width: '40%' }}
              />
            </Form.Item>
          </Form>
          <Table
            tableLayout="auto"
            dataSource={userData.value}
            columns={columns}
            loading={userData.loading}
            pagination={{
              position: ['bottomCenter'],
              pageSize: 30,
              showSizeChanger: false,
              total: totalCount || 0,
              current: currentPage || 1,
              onChange: (page) => setCurrentPage(page)
            }}
            scroll={{ x: 'max-content', y: 600, scrollToFirstRowOnChange: true }}
          >
            <Pagination />
          </Table>
        </Content>
      </DefaultLayout>
      <Modal width="calc(100vw - 48px)" cancelText="닫기" {...modal} />
      <Modal {...removeModal} />
      <ResetModal
        modalData={resetModal}
        closeModal={closeResetModal}
        userId={currentUserId || ''}
        refetch={fetchUserData}
      />
    </>
  );
}
