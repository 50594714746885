import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { FormDataType, ShortcutRecordData } from '@src/models/shortLink';
import { fixedSourceList } from '@src/constants/shortLink';
import MarketingInfo from './MarketingInfo';

const sourceOptions = [
  { label: 'Youtube', value: 'youtube' },
  { label: 'Facebook', value: 'meta' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Kakao', value: 'kakao' },
  { label: 'Notion', value: 'notion' },
  { label: 'Realworld', value: 'realworld' },
  { label: '직접 입력', value: 'etc' }
];

interface InternalFormProps {
  isModify?: boolean;
  isCopy?: boolean;
  prevData?: ShortcutRecordData;
  onFinish: (value: FormDataType) => unknown;
}

export default function InternalForm({ onFinish, prevData, isModify, isCopy }: InternalFormProps) {
  const { id, redirectTo, note } = prevData || {
    id: '',
    redirectTo: '',
    note: ''
  };

  const params = new URLSearchParams(redirectTo.split('?').slice(1).join('?'));

  const originalUrl = redirectTo.slice(redirectTo.indexOf('deeplink_path') + 14, redirectTo.indexOf('&utm_source'));
  const utmSource = params.get('utm_source');
  const utmMedium = params.get('utm_medium');
  const utmCampaign = params.get('utm_campaign');
  const utmContent = params.get('utm_content');
  const flow = params.get('flow');
  const notificationConfirm = decodeURIComponent(originalUrl).includes('notification_confirm=true');

  const [selectedValue, setSelectedValue] = React.useState(() => {
    if (isModify) {
      return fixedSourceList.includes(utmSource || '') ? utmSource : 'etc';
    }
    return '';
  });

  function getInitialValues() {
    if (isModify) {
      return {
        originalUrl: decodeURIComponent(originalUrl || '')
          .replace('?notification_confirm=true', '')
          .replace('&notification_confirm=true', ''),
        shortUrl: id,
        memo: note,
        utmSource: fixedSourceList.includes(utmSource || '') ? utmSource : 'etc',
        utmSourceEtc: fixedSourceList.includes(utmSource || '') ? '' : utmSource,
        utmMedium: utmMedium || '',
        utmCampaign: utmCampaign || '',
        utmContent: utmContent || '',
        flow: flow !== '0',
        notificationConfirm
      };
    }
    if (isCopy) {
      return {
        originalUrl: decodeURIComponent(originalUrl || '')
          .replace('?notification_confirm=true', '')
          .replace('&notification_confirm=true', ''),
        memo: note,
        utmSource: fixedSourceList.includes(utmSource || '') ? utmSource : 'etc',
        utmSourceEtc: fixedSourceList.includes(utmSource || '') ? '' : utmSource,
        utmMedium: utmMedium || '',
        utmCampaign: utmCampaign || '',
        utmContent: utmContent || '',
        flow: flow !== '0',
        notificationConfirm
      };
    }
    return {};
  }

  return (
    <Form initialValues={getInitialValues()} onFinish={onFinish} style={{ textAlign: 'left' }}>
      <Title level={5}>앱 내 연결할 페이지 주소</Title>
      <Form.Item name="originalUrl">
        <Input />
      </Form.Item>
      <Title level={5}>
        희망 단축 주소{' '}
        <span style={{ color: '#6c757d', fontSize: '0.8em', fontWeight: 'normal' }}>
          공백 시 자동 생성, 한 번 생성 시 변경 불가
        </span>
      </Title>
      <Form.Item name="shortUrl">
        <Input disabled={isModify} />
      </Form.Item>
      <Title level={5}>메모</Title>
      <Form.Item name="memo">
        <Input />
      </Form.Item>
      <Title level={5}>
        마케팅 관련 설정{' '}
        <Tooltip placement="top" title={<MarketingInfo />} overlayInnerStyle={{ width: 'max-content' }}>
          <QuestionCircleOutlined />
        </Tooltip>
      </Title>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item label="UTM Source" name="utmSource">
                <Select options={sourceOptions} onChange={(value) => setSelectedValue(value)} />
              </Form.Item>
            </Col>
            {selectedValue === 'etc' && (
              <Col span={12}>
                <Form.Item label="직접 입력" name="utmSourceEtc">
                  <Input />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
        <Col flex={1}>
          <Form.Item label="UTM Campaign" name="utmCampaign">
            <Input />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item label="UTM Medium" name="utmMedium">
            <Input />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item label="UTM Content" name="utmContent">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Title level={5}>회원가입 플로우</Title>
      <Form.Item name="flow" valuePropName="checked">
        <Checkbox>필요</Checkbox>
      </Form.Item>
      <Title level={5}>
        알림 설정 확인 여부{' '}
        <Tooltip title="체크할 경우 앱 이동 시 알림 설정 권한 팝업이 뜨게 됩니다.">
          <QuestionCircleOutlined />
        </Tooltip>
      </Title>
      <Form.Item name="notificationConfirm" valuePropName="checked">
        <Checkbox>필요</Checkbox>
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button htmlType="submit" type="primary">
          {isModify ? '수정하기' : '추가하기'}
        </Button>
      </div>
    </Form>
  );
}
