import { PageHeader } from '@ant-design/pro-components';
import { Divider, notification, Table, Button } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import copy from 'copy-to-clipboard';
import Modal from 'antd/es/modal/Modal';
import { useAsyncRetry } from 'react-use';
import { Story, StoryType, Theme } from '@uniquegood/realworld-admin-interface';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { getLocalStorage } from '@src/utils/localStorage';
import { noticeApi } from '@src/apis/admin';
import { APP_ENV } from '@src/config';
import ModalContent from './components/ModalContent';

const EVENT_PAGE_URL = {
  production: 'https://event.realworld.to',
  development: 'https://develop.realworld-event.pages.dev'
}[APP_ENV];

export default function EventPage() {
  const { modal, openModal, closeModal } = useModalState();

  const accessToken = getLocalStorage('token');

  const data = useAsyncRetry(() => {
    return noticeApi.getStoryList({
      headers: { Authorization: `Bearer ${accessToken}` }
    });
  }, []);

  function dataRefetching() {
    data.retry();
  }

  const columns: ColumnType<Story>[] = [
    {
      title: 'Story ID',
      dataIndex: 'id',
      width: '190px',
      render: (id: string, data) => (
        <Button
          onClick={() => {
            openModal({
              title: '이벤트 페이지 수정',
              children: (
                <ModalContent
                  type="update"
                  onClose={closeModal}
                  data={data}
                  storyId={id}
                  refetchHandler={dataRefetching}
                />
              )
            });
          }}
        >
          <code>{id}</code>
        </Button>
      )
    },
    {
      title: '제목',
      dataIndex: 'title'
    },
    {
      title: '내용',
      dataIndex: 'content'
    },
    {
      title: '타입',
      dataIndex: 'type',
      width: '80px',
      render: (type: StoryType) => storyTypeToLocalString[type]
    },
    {
      title: '배경',
      dataIndex: 'backgroundImage',
      width: '80px',
      render: (image: string) =>
        image ? (
          <Button size="small" type="link" href={image} target="_blank" rel="noreferrer">
            보기
          </Button>
        ) : (
          <Button size="small" type="link" disabled>
            없음
          </Button>
        )
    },

    {
      title: '테마',
      dataIndex: 'theme',
      width: '80px',
      render: (theme: Theme) => themeToLocalString[theme]
    },
    {
      title: '활성 여부',
      dataIndex: 'isDisabled',
      width: '90px',
      render: (isDisabled: boolean) => (isDisabled ? '비활성' : '활성')
    },
    {
      title: '게임 수',
      dataIndex: 'projectIds',
      width: '80px',
      render: (projectIds: string[]) => projectIds.length
    },
    {
      title: '동작',
      key: 'url',
      width: '110px',
      render: (_, { id }) => (
        <>
          <Button
            size="small"
            icon={<CopyOutlined />}
            style={{ marginRight: '0.5em' }}
            onClick={() => {
              notification.success({
                message: '스토리가 복사되었습니다.',
                placement: 'bottomRight',
                icon: <CopyOutlined />
              });
              copy(
                `https://realworld.app.link?deeplink_path=realworld://story?storyId=${id}&channel=Link&campaign=URL&event_name=click_story_link&feature=Story&$desktop_url=${EVENT_PAGE_URL}/stories/${id}&$ios_url=http://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1391415522&mt=8&$android_url=market://details?id=biz.uniquegood.realworld.sunguard`
              );
            }}
          >
            URL 복사
          </Button>
          <Button
            size="small"
            style={{ marginRight: '0.5em' }}
            icon={<CopyOutlined />}
            onClick={() => {
              notification.success({
                message: '스키마가 복사되었습니다.',
                placement: 'bottomRight',
                icon: <CopyOutlined />
              });
              copy(`realworld://story?storyId=${id}`);
            }}
          >
            스키마 복사
          </Button>
          <Button
            danger
            size="small"
            icon={<DeleteOutlined />}
            onClick={async () => {
              try {
                await noticeApi.deleteStory(id!, {
                  headers: { Authorization: `Bearer ${accessToken}` }
                });

                notification.success({
                  message: '삭제 완료!',
                  description: '이벤트 페이지가 삭제되었습니다.',
                  icon: <DeleteOutlined />,
                  placement: 'bottomRight'
                });
              } catch (error) {
                notification.error({
                  message: '삭제 실패',
                  description: '이벤트 페이지 삭제에 실패했습니다.',
                  placement: 'bottomRight'
                });
              }
              await dataRefetching();
            }}
          >
            삭제
          </Button>
        </>
      )
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="이벤트 페이지 목록"
        subTitle="등록된 이벤트 페이지 목록입니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />

      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ width: 'fit-content', marginBottom: '16px' }}
          onClick={() => {
            openModal({
              title: '이벤트 페이지 추가',
              children: <ModalContent type="create" onClose={closeModal} refetchHandler={dataRefetching} />
            });
          }}
        >
          이벤트 페이지 추가
        </Button>
        <Table<Story>
          columns={columns}
          rowKey={(record) => record.id!}
          dataSource={data.value?.data.stories ?? undefined}
          scroll={{ y: 600 }}
        />
      </Content>
      <Modal {...modal} footer={null} />
    </DefaultLayout>
  );
}

const storyTypeToLocalString = {
  [StoryType.TextHeader]: 'A타입 (텍스트 타입)',
  [StoryType.ImageHeader]: 'B타입 (이미지 타입)',
  [StoryType.WebView]: 'C타입 (웹뷰 타입)'
} as const satisfies Record<StoryType, string>;

const themeToLocalString = {
  [Theme.Light]: '라이트',
  [Theme.Dark]: '다크'
} as const satisfies Record<Theme, string>;
