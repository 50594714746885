import axios from 'axios';

import { ADMIN_SERVER_HOST } from '@src/config';

export interface PatchGiftCardsProps {
  accessToken: string;
  giftCodes: string[];
  keyword?: string;
  price?: number;
  isActivate?: boolean;
}

export async function patchGiftCards({ accessToken, giftCodes, keyword, price, isActivate }: PatchGiftCardsProps) {
  try {
    await axios.patch(
      `https://${ADMIN_SERVER_HOST}/manage/api/giftCards`,
      {
        giftCodes,
        keyword,
        price,
        isActivate
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    } else {
      throw error;
    }
  }
}
