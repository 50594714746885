import React from 'react';
import { useAsync } from 'react-use';
import { QuestionCircleFilled, UploadOutlined } from '@ant-design/icons';
import {
  CommunityBannerResponseModel,
  CommunityBannerType,
  UpdateCommunityBannerRequestModel
} from '@uniquegood/realworld-admin-interface';
import { Button, Form, Input, Modal, ModalProps, Select, Tooltip, Upload, message } from 'antd';
import { communityBannerApi, fileApi, projectApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface ModifyBannerModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  refetch: () => unknown;
  initialData: CommunityBannerResponseModel | undefined;
}

export default function ModifyBannerModal({ modalData, closeModal, refetch, initialData }: ModifyBannerModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const [selectedBannerType, setSelectedBannerType] = React.useState<CommunityBannerType | undefined>();

  const projects = useAsync(async () => {
    try {
      const { data: preData } = await projectApi.getProjects(undefined, undefined, undefined, 1, 1, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const { data } = await projectApi.getProjects(undefined, undefined, undefined, 1, preData.data.count, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return data.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);

  const handleSubmit = async (
    values: UpdateCommunityBannerRequestModel & {
      image: { file: File; fileList: FileList };
      noticeResponse: {
        title: string;
        content: string;
        images: { fileList: FileList };
        linkedProjects: string[];
      };
    }
  ) => {
    try {
      let imageUrl = '';

      if (values.image.file instanceof File) {
        const {
          data: {
            data: { url }
          }
        } = await fileApi.uploadFile(undefined, undefined, values.image.file, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        imageUrl = url || '';
      } else {
        imageUrl = (values.image.file as { url: string }).url;
      }

      const imageIds =
        initialData?.bannerType === CommunityBannerType.Notice
          ? await Promise.all(
              Array.from(values.noticeResponse.images.fileList).map(async (file) => {
                const fileData = file as File & { originFileObj: File; uid?: string };
                if (fileData.uid?.includes('prev')) {
                  return fileData.uid?.split('-')[1];
                }
                const { data } = await fileApi.uploadFile(undefined, undefined, fileData.originFileObj, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`
                  }
                });

                return data.data.id || '';
              })
            )
          : null;

      const { data } = await communityBannerApi.updateBanner(
        initialData?.id || '',
        {
          ...values,
          title: values.title,
          subTitle: values.subTitle,
          linkUrl: values.linkUrl,
          imageUrl,
          noticeRequest:
            initialData?.bannerType === CommunityBannerType.Notice
              ? {
                  title: values.noticeResponse.title,
                  content: values.noticeResponse.content,
                  imageIds,
                  linkedProjectIds: values.noticeResponse.linkedProjects
                }
              : undefined
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        message.success('배너를 수정했습니다.');
        closeModal();
        await refetch();
      }
    } catch (e) {
      console.error(e);
      message.error('배너 수정에 실패했습니다.');
    }
  };

  const setInitialValue = () => {
    form.setFieldsValue({
      ...initialData,
      image: {
        file: { uid: 'prev', name: '이전 이미지', url: initialData?.imageUrl || '' },
        fileList: [{ uid: 'prev', name: '이전 이미지', url: initialData?.imageUrl || '' }]
      },
      linkUrl: initialData?.linkUrl || '',
      noticeResponse: {
        ...initialData?.noticeResponse,
        images: {
          fileList: initialData?.noticeResponse?.images?.map((image, index) => ({
            uid: `prev-${image.id}`,
            name: `이전 이미지 ${index + 1}`,
            url: image.url || ''
          }))
        },
        linkedProjects: initialData?.noticeResponse?.linkedProjects?.map((project) => project.id)
      }
    });

    setSelectedBannerType(initialData?.bannerType);
  };

  React.useEffect(() => {
    setInitialValue();
  }, [initialData]);

  return (
    <Modal
      {...modalData}
      onOk={form.submit}
      okText="확인"
      cancelText="닫기"
      afterClose={setInitialValue}
      title={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          배너 수정
          <Tooltip title="배너 운영/제작 가이드를 확인하시려면 클릭하세요!">
            <QuestionCircleFilled
              onClick={() =>
                window.open('https://www.notion.so/uniquegoodcompany/83dff5eaf6884d7790b4ecafb5dd0dce?pvs=4', '_blank')
              }
              style={{
                width: '16px',
                height: '16px',
                marginLeft: '4px',
                color: '#444',
                cursor: 'pointer'
              }}
            />
          </Tooltip>
        </div>
      }
    >
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Form.Item name="title" label="제목" rules={[{ required: true }]}>
          <Input.TextArea autoSize={{ minRows: 2 }} placeholder="제목을 입력해주세요." />
        </Form.Item>
        <Form.Item name="subTitle" label="부제목">
          <Input placeholder="부제목을 입력해주세요." />
        </Form.Item>
        <Form.Item name="linkUrl" label="클릭 시 이동할 주소" rules={[{ required: true }]}>
          <Input
            onClick={async () => {
              await navigator.clipboard.writeText(initialData?.linkUrl || '');

              message.success('클립보드에 복사되었습니다.');
            }}
            placeholder="클릭 시 이동할 주소를 입력해주세요."
            readOnly={initialData?.bannerType === CommunityBannerType.Notice}
          />
        </Form.Item>
        <Form.Item name="image" label="이미지" rules={[{ required: true }]}>
          <Upload
            defaultFileList={[{ uid: 'prev', name: '이전 이미지', url: initialData?.imageUrl || '' }]}
            maxCount={1}
            beforeUpload={() => false}
            listType="picture"
          >
            <Button>
              <UploadOutlined />
              이미지 업로드
            </Button>
          </Upload>
        </Form.Item>
        {selectedBannerType === CommunityBannerType.Notice && (
          <>
            <Form.Item name={['noticeResponse', 'title']} label="공지글 제목" rules={[{ required: true }]}>
              <Input placeholder="공지글 제목을 입력해주세요." />
            </Form.Item>
            <Form.Item name={['noticeResponse', 'content']} label="공지글 내용">
              <Input.TextArea autoSize={{ minRows: 3 }} placeholder="공지글 내용을 입력해주세요." />
            </Form.Item>
            <Form.Item name={['noticeResponse', 'images']} label="공지 이미지" valuePropName="file">
              <Upload
                defaultFileList={initialData?.noticeResponse?.images?.map((image, index) => ({
                  uid: `prev-${image.id}`,
                  name: `이전 이미지 ${index + 1}`,
                  url: image.url || ''
                }))}
                beforeUpload={() => false}
                listType="picture"
              >
                <Button>
                  <UploadOutlined />
                  이미지 업로드
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item name={['noticeResponse', 'linkedProjects']} label="프로젝트">
              <Select
                mode="multiple"
                placeholder="프로젝트를 선택해주세요."
                options={projects.value?.data?.map((project) => ({
                  label: project.name,
                  value: project.id
                }))}
                loading={projects.loading}
                filterOption={(input, option) => {
                  if (!option) return false;

                  return option.label?.includes(input) || false;
                }}
                disabled={projects.loading}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}
