import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import produce, { castDraft } from 'immer';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { ProjectLite } from '@uniquegood/realworld-admin-interface/dist';

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#e6f4ff' : 'none',
  display: 'flex',
  padding: '10px 6px',
  overflow: 'auto'
});

export function useProjects(originProjects: ProjectLite[]) {
  const [projects, setProjects] = useState<ProjectLite[]>(originProjects);

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return;

    setProjects(
      produce((draft) => {
        if (!result.destination) return;
        const [reorderedItem] = draft.splice(result.source.index, 1);
        draft.splice(result.destination.index, 0, reorderedItem);
      })
    );
  };

  function onItemRemove(index: number) {
    setProjects(
      produce((draft) => {
        draft.splice(index, 1);
      })
    );
  }

  function onItemAdd(project: ProjectLite) {
    setProjects(
      produce((draft) => {
        draft.push(castDraft(project));
      })
    );
  }
  return { projectItems: projects, onDragEnd, onItemRemove, onItemAdd };
}

interface Props {
  projectItems: ProjectLite[];
  onDragEnd: OnDragEndResponder;
  onItemRemove: (index: number) => unknown;
}

function Projects({ projectItems, onDragEnd, onItemRemove }: Props) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-projects" direction="horizontal">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {projectItems.map(({ id, name, verticalImageUrl, imageUrl }, index) => {
              return (
                <Draggable key={id} draggableId={String(id)} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ margin: '0 4px', ...provided.draggableProps.style }}
                    >
                      <Card
                        style={{ width: 160 }}
                        bodyStyle={{ padding: '12px' }}
                        cover={
                          <div>
                            <img
                              alt="example"
                              src={verticalImageUrl ?? (imageUrl || '')}
                              style={{ position: 'relative', width: '100%' }}
                            />
                            <Button
                              type="text"
                              style={{ position: 'absolute', right: '0px', color: 'white' }}
                              icon={<CloseOutlined />}
                              onClick={() => onItemRemove(index)}
                            />
                          </div>
                        }
                      >
                        <Meta title={name} />
                      </Card>
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default Projects;
