import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Divider, Input, Table, Tag, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { ProjectListViewResponseModel } from '@uniquegood/realworld-admin-interface';
import { ColumnsType } from 'antd/es/table';
import {
  parseAdminExpiredTokenError,
  parseAdminInvalidTokenError,
  parseAdminLoginRequiredError
} from '@src/apis/admin-old/error/parseError';
import DefaultLayout from '@src/components/DefaultLayout';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import Button from '@src/components/Button';
import { getLocalStorage } from '@src/utils/localStorage';
import { projectApi } from '@src/apis/admin';

const { Search } = Input;

function GiftCardContentListPage() {
  const accessToken = getLocalStorage('token');
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [contentPerPage, setContentPerPage] = useState(50);
  const [totalContents, setTotalContents] = useState<number>();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [contents, setContents] = useState<ProjectListViewResponseModel[]>([]);
  const getOriginContents = React.useCallback(
    async (pageNumber: number, contentPerPage: number) => {
      if (!accessToken) {
        throw new Error('No access token');
      }

      try {
        const { data } = await projectApi.getOriginalProjects(searchKeyword, pageNumber, contentPerPage, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        setTotalContents(data.data.count);
        setContents(
          data.data.data?.map((i: any) => ({
            ...i,
            priceLabel: `${i.price ?? 0} 원`,
            isLockedDefaultLabel: i.isLockedDefault ? 'O' : 'X',
            isVisibleDefaultLabel: i.isVisibleDefault ? 'O' : 'X'
          })) || []
        );
      } catch (error) {
        if (
          parseAdminLoginRequiredError(error)[1] ||
          parseAdminExpiredTokenError(error)[1] ||
          parseAdminInvalidTokenError(error)[1]
        ) {
          navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
        }
        console.error(error);
      }
    },
    [accessToken, navigate, searchKeyword]
  );

  useEffect(() => {
    getOriginContents(pageNumber, contentPerPage);
  }, [getOriginContents, searchKeyword, pageNumber, contentPerPage]);

  const columns: ColumnsType<ProjectListViewResponseModel> = [
    {
      title: '채널명',
      dataIndex: 'channelName',
      key: 'channelName'
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '간단설명',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '가격',
      dataIndex: 'priceLabel',
      key: 'priceLabel',
      width: '100px'
    },
    {
      title: '잠금 여부',
      dataIndex: 'isLockedDefaultLabel',
      key: 'isLockedDefaultLabel',
      render: (isLockedDefaultLabel: string) => <Tag>{isLockedDefaultLabel}</Tag>,
      width: '120px'
    },
    {
      title: '기본 노출 여부',
      dataIndex: 'isVisibleDefaultLabel',
      key: 'isVisibleDefaultLabel',
      render: (isVisibleDefaultLabel: string) => <Tag>{isVisibleDefaultLabel}</Tag>,
      width: '120px'
    },
    {
      title: '조회 / 발급',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link
            to={pageKeyToPathnameFn[PageKey.GIFT_CARD_LIST]({
              querystring: { projects: record.id }
            })}
          >
            <Button>조회</Button>
          </Link>
          <Link to={pageKeyToPathnameFn[PageKey.GIFT_CARD_CREATE]({ keys: [record.id || ''] })}>
            <Button>발급</Button>
          </Link>
        </Space>
      )
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="기프트카드 콘텐츠 관리 페이지"
        subTitle="리얼월드 오리지널 콘텐츠의 기프트카드를 조회, 발급합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Search
          placeholder="검색어를 입력하세요"
          onSearch={(e) => setSearchKeyword(e)}
          enterButton
          style={{ marginBottom: '16px', width: '40%' }}
        />
        <Table
          columns={columns}
          dataSource={contents}
          rowKey="id"
          pagination={{
            pageSizeOptions: [50, 100],
            defaultPageSize: 50,
            total: totalContents,
            pageSize: contentPerPage,
            onChange: (page, pageSize) => {
              setPageNumber(page);
              setContentPerPage(pageSize);
            }
          }}
          scroll={{ y: 600 }}
        />
      </Content>
    </DefaultLayout>
  );
}

export default GiftCardContentListPage;
