import { CreateOrUpdateMainPageSectionRequestModel, SectionType } from '@uniquegood/realworld-admin-interface';
import { Form, Input, Modal, ModalProps, Select, message } from 'antd';
import { mainPageSectionApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { sectionTypeToString } from '@src/constants/section';

type CreateModalProps = {
  modalData: ModalProps;
  closeModal: () => unknown;
  refetch: () => unknown;
  groupId?: string;
};

export default function CreateModal({ modalData, closeModal, refetch, groupId }: CreateModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const handleSubmit = async (values: CreateOrUpdateMainPageSectionRequestModel) => {
    try {
      if (!groupId) return;

      let requestValues = null;

      if (values.type === SectionType.PlayersRecommendGames) {
        requestValues = {
          ...values,
          sectionData: {
            recommendedProjects: []
          }
        };
      } else if (values.type === SectionType.ImageSlideBanners) {
        requestValues = {
          ...values,
          sectionData: {
            imageBanners: []
          }
        };
      } else if (values.type === SectionType.RecommendedKeywords) {
        requestValues = {
          ...values,
          sectionData: {
            recommendedKeywords: []
          }
        };
      } else {
        requestValues = values;
      }

      const { data } = await mainPageSectionApi.createMainPageSection(
        groupId,
        {
          ...requestValues,
          isDisabled: values.isDisabled
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        message.success('섹션을 생성했습니다.');

        closeModal();

        refetch();
      }
    } catch (e) {
      console.error(e);

      message.error('섹션 생성에 실패했습니다.');
    }
  };

  return (
    <Modal {...modalData} title="섹션 생성" onOk={form.submit} okText="확인" cancelText="닫기">
      <Form
        form={form}
        onFinish={handleSubmit}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        preserve={false}
        validateMessages={{ required: '필수 입력값입니다.' }}
      >
        <Form.Item name="name" label="이름" rules={[{ required: true }]}>
          <Input placeholder="섹션 이름을 입력해주세요." />
        </Form.Item>
        <Form.Item name="type" label="타입" rules={[{ required: true }]}>
          <Select
            placeholder="섹션 타입을 선택해주세요."
            options={Object.keys(SectionType).map((type) => ({
              label: sectionTypeToString[type as SectionType],
              value: type
            }))}
          />
        </Form.Item>
        <Form.Item name="isDisabled" label="활성화 여부" rules={[{ required: true }]}>
          <Select
            placeholder="활성화 여부를 선택해주세요."
            options={[
              { label: '활성화', value: false },
              { label: '비활성화', value: true }
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
