import { AmuletCreateOrUpdateRequestModel } from '@uniquegood/realworld-admin-interface';
import { Form, Input, Modal, ModalProps } from 'antd';
import React from 'react';

interface ModifyAmuletModalProps {
  modalData: ModalProps;
  name: string;
  description: string;
  imageUrl: string;
  category: string;
  onSubmit: (values: AmuletCreateOrUpdateRequestModel) => unknown;
}

export default function ModifyAmuletModal({
  modalData,
  name,
  description,
  imageUrl,
  category,
  onSubmit
}: ModifyAmuletModalProps) {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.submit();
  };

  React.useEffect(() => {
    form.setFieldsValue({
      name,
      description,
      imageUrl,
      category
    });
  }, []);

  return (
    <Modal {...modalData} onOk={handleSubmit} okText="확인" cancelText="닫기" forceRender>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item label="부적 이름" name="name" rules={[{ required: true, message: '이름을 입력해주세요.' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="부적 이미지"
          name="imageUrl"
          rules={[{ required: true, message: '이미지 URL을 입력해주세요.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="부적 설명" name="description" rules={[{ required: true, message: '설명을 입력해주세요.' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="부적 카테고리" name="category">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
