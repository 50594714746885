import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Select,
  Space,
  Table,
  message
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import {
  ActionSchemaType,
  PromotionRewardCreateOrUpdateRequestModel,
  PromotionType
} from '@uniquegood/realworld-admin-interface/dist';
import { useAsync } from 'react-use';
import { uuidv4 } from '@src/utils/uuid';
import useModalState from '@src/hooks/useModalState';
import { promotionApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { SchemaTypeLabel } from '@src/constants/point';
import { PromotionRewardCreateModal } from './PromotionRewardCreateModal';

/**
 * schema
 * title: string
 * description: string
 * joinEnableCount: number
 * startedAt: Date
 * endedAt: Date
 * promotionType: Daily | Basic
 * manager: string
 * viewType: ?
 * promotionRewards: [
 *   {
 *     rewardPoint: number
 *     description: string
 *     isActivate: boolean
 *     message: string
 *   }
 * ]
 * isActivate: boolean
 */

interface PromotionFormType {
  date: [Dayjs, Dayjs];
  description: string;
  promotionType: PromotionType;
  schemaType: ActionSchemaType;
  title: string;
  enTitle: string;
  joinEnableCount: number;
  isActivate: boolean;
}

export function PromotionModifyModal({
  modal,
  promotionId,
  refetchFn,
  closeModifyModal
}: {
  modal: ModalProps;
  promotionId: string;
  refetchFn: () => unknown;
  closeModifyModal: () => unknown;
}) {
  const { openModal, closeModal, modal: rewardCreateModal } = useModalState();
  const accessToken = getLocalStorage('token');
  const [form] = Form.useForm();

  const [rewardList, setRewardList] = React.useState<
    (PromotionRewardCreateOrUpdateRequestModel & { key: React.Key })[]
  >([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [isEventPermanent, setIsEventPermanent] = React.useState(false);
  const [manager, setManager] = React.useState('');

  useAsync(async () => {
    if (!promotionId) return null;

    const { data } = await promotionApi.getPromotion(promotionId, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (data.data?.startedAt === null || data.data?.endedAt === null) {
      setIsEventPermanent(true);
    } else {
      setIsEventPermanent(false);
    }

    form.setFieldsValue({
      ...data.data,
      date:
        data.data?.startedAt === null || data.data?.endedAt === null
          ? [null, null]
          : [dayjs(data.data?.startedAt), dayjs(data.data?.endedAt)]
    });

    setManager(data.data?.manager || '');

    setRewardList(
      data.data?.promotionRewards?.map(
        (item) =>
          ({
            key: uuidv4(),
            rewardPoint: item.rewardPoint,
            description: item.rewardDescription,
            isActivate: item.isActivate,
            message: item.message
          } as unknown as PromotionRewardCreateOrUpdateRequestModel & { key: React.Key })
      ) || []
    );

    return data;
  }, [promotionId]);

  const columns = [
    {
      key: 'rewardPoint',
      dataIndex: 'rewardPoint',
      title: '보상 츄로',
      editable: true
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: '설명',
      editable: true
    },
    {
      key: 'isActivate',
      dataIndex: 'isActivate',
      title: '활성화 여부',
      editable: true,
      render: (value: boolean) => {
        return value ? '활성화' : '비활성화';
      }
    },
    {
      key: 'message',
      dataIndex: 'message',
      title: '보상 획득 메시지',
      editable: true
    }
  ];

  const handleAddClick = () => {
    openModal({
      title: '프로모션 보상 추가'
    });
  };

  const handleFinish = async (values: PromotionFormType) => {
    const { date, description, promotionType, schemaType, title, enTitle, joinEnableCount, isActivate } = values;

    const [startDate, endDate] = !isEventPermanent ? date.map((item) => item.format()) : [null, null];

    await promotionApi.editPromotion(
      promotionId,
      {
        title,
        enTitle,
        description,
        manager,
        joinEnableCount,
        promotionType,
        schemaType,
        startedAt: startDate,
        endedAt: endDate,
        promotionRewards: rewardList,
        isActivate
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    await refetchFn();
    message.success('프로모션을 수정했습니다.');
    closeModifyModal();
  };

  const handleSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleDeleteClick = () => {
    setRewardList((prev) => prev.filter((item) => !selectedRowKeys.includes(item.key)));
  };

  return (
    <Modal onOk={() => form.submit()} {...modal} okText="저장" cancelText="닫기" width={800}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Form onFinish={handleFinish} form={form} style={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="title"
              rules={[{ required: true, message: '프로모션 이름을 입력해주세요.' }]}
              style={{ flex: 1, marginRight: '8px' }}
            >
              <Input placeholder="프로모션 이름" />
            </Form.Item>
            <Form.Item
              name="enTitle"
              rules={[{ required: true, message: '프로모션 영문 이름을 입력해주세요.' }]}
              style={{ flex: 1 }}
            >
              <Input placeholder="프로모션 영문 이름" />
            </Form.Item>
          </div>
          <Space style={{ width: '100%' }}>
            <Form.Item
              name="promotionType"
              rules={[{ required: true, message: '프로모션 타입을 입력해주세요.' }]}
              style={{ width: '100%' }}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="프로모션 타입"
                options={[
                  {
                    value: 'Basic',
                    label: '기본형'
                  },
                  {
                    value: 'Daily',
                    label: '데일리형'
                  },
                  {
                    value: 'Multi',
                    label: '멀티형'
                  }
                ]}
              />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="schemaType"
              rules={[{ required: true, message: '스키마 타입을 선택해주세요.' }]}
            >
              <Select
                placeholder="스키마 타입"
                options={Object.keys(ActionSchemaType).map((item) => ({
                  value: item,
                  label: SchemaTypeLabel[item as ActionSchemaType]
                }))}
                style={{ width: '200px' }}
              />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name="isActivate"
              rules={[{ required: true, message: '활성화 여부를 선택해주세요.' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="활성화 여부"
                options={[
                  {
                    value: true,
                    label: '활성화'
                  },
                  {
                    value: false,
                    label: '비활성화'
                  }
                ]}
              />
            </Form.Item>
            <Form.Item name="joinEnableCount" rules={[{ required: true, message: '참여 횟수를 입력해주세요.' }]}>
              <InputNumber min={0} placeholder="참여 가능 횟수" style={{ width: '150px' }} />
            </Form.Item>
          </Space>
          <Form.Item name="description" rules={[{ required: true, message: '프로모션 내용을 입력해주세요.' }]}>
            <Input placeholder="프로모션 내용" />
          </Form.Item>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '32px' }}>
            <div style={{ flexShrink: 0 }}>
              <Checkbox checked={isEventPermanent} onChange={(e) => setIsEventPermanent(e.target.checked)}>
                상시
              </Checkbox>
            </div>
            <Form.Item
              name="date"
              rules={[
                {
                  required: !isEventPermanent,
                  message: '프로모션 기간을 입력해주세요.'
                }
              ]}
              style={{ width: '100%', marginBottom: 0 }}
            >
              <DatePicker.RangePicker
                picker="date"
                disabled={isEventPermanent}
                disabledDate={(date) => {
                  if (date.isBefore(dayjs())) {
                    return true;
                  }
                  return false;
                }}
                showTime={{ format: 'HH:mm' }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            {selectedRowKeys.length > 0 ? <Button onClick={handleDeleteClick}>선택 항목 삭제</Button> : <div />}
            <Button onClick={handleAddClick}>보상 추가</Button>
          </div>
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: handleSelectChange
            }}
            dataSource={rewardList}
            columns={columns}
            scroll={{ y: 300 }}
            pagination={false}
          />
        </Form>
      </Space>
      <PromotionRewardCreateModal
        closeModal={closeModal}
        modal={rewardCreateModal}
        list={rewardList}
        setList={setRewardList}
      />
    </Modal>
  );
}
