import { Image, Modal, Popconfirm, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CommunityPostReportDetailResponseModel, ReportState } from '@uniquegood/realworld-admin-interface/dist';
import { communityReportReasonLabel } from '@src/constants/communityReason';
import { communityReportStateLabel } from '@src/constants/communityState';
import { communityReportReason } from '@src/models/communityReportReason';
import { communityReportState } from '@src/models/communityReportState';
import Button from '../Button';

interface Props {
  reportDetail: CommunityPostReportDetailResponseModel;
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleReportProcess: (process: ReportState) => void;
}

interface ReportDetailImageType {
  id: string;
  url: string;
}

function ReportDetailModal({ reportDetail, isModalOpen, handleModalClose, handleReportProcess }: Props) {
  const ModalData = [
    {
      ...reportDetail,
      key: reportDetail?.reportId,
      createdAtLabel: dayjs(reportDetail?.createdAt).format('YYYY-MM-DD'),
      isDeletedLabel: reportDetail?.isDeleted ? 'O' : 'X',
      stateLabel: communityReportStateLabel[reportDetail?.state as communityReportState],
      reasonLabel: communityReportReasonLabel[reportDetail?.reason as communityReportReason],
      images: reportDetail?.images
    }
  ];

  return (
    <Modal
      width="70%"
      open={isModalOpen}
      title="신고 내역 상세보기"
      onCancel={handleModalClose}
      footer={[
        <Popconfirm
          key={1}
          title="정말 삭제처리 하시겠습니까?"
          okText="네"
          cancelText="아니오"
          placement="bottom"
          icon={<ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />}
          onConfirm={() => handleReportProcess(ReportState.Deleted)}
        >
          <Button key="delete" type="primary" value="delete" danger>
            삭제처리
          </Button>
        </Popconfirm>,
        <Popconfirm
          key={2}
          title="정말 스포일러처리 하시겠습니까?"
          okText="네"
          cancelText="아니오"
          placement="bottom"
          icon={<ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />}
          onConfirm={() => handleReportProcess(ReportState.Censored)}
        >
          <Button key="delete" type="primary" value="delete" danger>
            스포일러처리
          </Button>
        </Popconfirm>,
        <Popconfirm
          key={3}
          title="정말 반려처리 하시겠습니까?"
          okText="네"
          cancelText="아니오"
          placement="bottom"
          icon={<ExclamationCircleOutlined style={{ color: '#faad14' }} />}
          onConfirm={() => handleReportProcess(ReportState.Rejected)}
        >
          <Button key="reject" type="default" value="reject" danger>
            반려처리
          </Button>
        </Popconfirm>
      ]}
    >
      <Table size="small" pagination={false} dataSource={ModalData}>
        <Table.Column key="createdAtLabel" title="신고 일자" dataIndex="createdAtLabel" width="120px" />
        <Table.Column key="reasonLabel" title="신고 분류" dataIndex="reasonLabel" width="120px" />
        <Table.Column key="description" title="신고 사유" dataIndex="description" />
      </Table>
      <Table size="small" pagination={false} dataSource={ModalData}>
        <Table.Column key="reportedUserName" title="작성자" dataIndex="reportedUserName" width="120px" />
        <Table.Column key="content" title="신고내용" dataIndex="content" />
        <Table.Column
          key="images"
          title="이미지"
          dataIndex="images"
          width="200px"
          render={(images: ReportDetailImageType[] | undefined) =>
            images
              ? images.map((i: ReportDetailImageType) => {
                  return <Image width={200} key={i.id} src={i.url} />;
                })
              : '없음'
          }
        />
      </Table>
      <Table size="small" pagination={false} dataSource={ModalData}>
        <Table.Column
          key="stateLabel"
          title="신고 상태"
          dataIndex="stateLabel"
          render={(stateLabel: string) => <Tag>{stateLabel}</Tag>}
        />
        <Table.Column key="isDeletedLabel" title="삭제 여부" dataIndex="isDeletedLabel" />
      </Table>
    </Modal>
  );
}

export default ReportDetailModal;
