import { Button, Form, InputNumber, Modal, ModalProps, Popconfirm, message } from 'antd';
import React from 'react';
import { pointApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface RetrieveModalProps {
  userId: string;
  modalData: ModalProps;
  closeModal: () => unknown;
  refetchFn: () => unknown;
  parentRefetchFn?: () => unknown;
}

export function RetrieveModal({ userId, modalData, closeModal, refetchFn, parentRefetchFn }: RetrieveModalProps) {
  const accessToken = getLocalStorage('token');

  const [input, setInput] = React.useState<number | null>();

  const handleConfirm = async () => {
    if (!input) return;

    try {
      await pointApi.deductionUsesPoint(
        userId,
        {
          freePoint: input
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      refetchFn();
      parentRefetchFn?.();
      closeModal();
    } catch (error) {
      console.error(error);

      // TODO: 임시로 에러처리 했음. 서버에서 내려주는 대로 바꿔야 함.
      message.error({
        content: '보유한 츄로보다 많은 수량의 츄로를 회수할 수 없습니다.'
      });
    }
  };

  return (
    <Modal
      {...modalData}
      onCancel={closeModal}
      okText="회수하기"
      afterClose={() => setInput(null)}
      cancelText="닫기"
      footer={[
        <Button key="close" onClick={closeModal}>
          닫기
        </Button>,
        <Popconfirm key="ok" title="정말 회수하시겠습니까?" onConfirm={handleConfirm} okText="확인" cancelText="취소">
          <Button key="ok" type="primary">
            회수하기
          </Button>
        </Popconfirm>
      ]}
    >
      <Form style={{ width: '100%' }}>
        <Form.Item style={{ width: '100%' }}>
          <InputNumber
            width={300}
            min={0}
            value={input}
            onChange={(value) => setInput(value)}
            placeholder="회수할 츄로 수량"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
