import { PreferenceResponseModel } from '@uniquegood/realworld-admin-interface';
import { Checkbox, Form, Input, Modal, ModalProps } from 'antd';

interface CreateTasteModalProps {
  modalData: ModalProps;
  onFinish: (values: PreferenceResponseModel) => unknown;
}

export default function CreateTasteModal({ modalData, onFinish }: CreateTasteModalProps) {
  const [form] = Form.useForm();

  return (
    <Modal
      title="취향 생성하기"
      transitionName=""
      onOk={() => form.submit()}
      okText="확인"
      cancelText="닫기"
      {...modalData}
      forceRender
      afterClose={() => form.resetFields()}
    >
      <Form form={form} onFinish={onFinish} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="right">
        <Form.Item label="취향 이름" name="name" rules={[{ required: true, message: '이름을 입력해주세요.' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="이미지 URL"
          name="imageUrl"
          rules={[{ required: true, message: '이미지 URL을 입력해주세요.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="설명" name="description">
          <Input />
        </Form.Item>
        <Form.Item label="활성화 여부" name="isActivate" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
}
