import { UserRecommendedProjectResponseModel } from '@uniquegood/realworld-admin-interface';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Button, Tooltip, message } from 'antd';
import React from 'react';
import { css } from '@emotion/react';
import { useAsync } from 'react-use';
import { uuidv4 } from '@src/utils/uuid';
import useModalState from '@src/hooks/useModalState';
import { projectApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import CreateModal from './CreateModal';
import UserRecommendedProjectItem from './UserRecommendedProjectItem';

type UserRecommendedProjectListProps = {
  userRecommendedProjects: UserRecommendedProjectResponseModel[];
  onChange: (values: (UserRecommendedProjectResponseModel & { id: string })[]) => unknown;
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#e6f4ff' : 'none',
  // display: 'flex',
  padding: '16px 0'
});

export default function UserRecommendedProjectList({
  userRecommendedProjects,
  onChange
}: UserRecommendedProjectListProps) {
  const accessToken = getLocalStorage('token');

  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();

  const totalProjects = useAsync(async () => {
    const { data: preResponse } = await projectApi.getProjects(undefined, undefined, undefined, 1, 1, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const { data } = await projectApi.getProjects(undefined, undefined, undefined, 1, preResponse.data.count, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return data.data.data;
  }, []);

  const userRecommendedProjectsWithId = React.useMemo(
    () =>
      userRecommendedProjects.map((userRecommendedProject) => ({
        ...userRecommendedProject,
        id: uuidv4()
      })),
    []
  );

  const [projects, setProjects] = React.useState(userRecommendedProjectsWithId);

  const handleDragEnd: OnDragEndResponder = async (result) => {
    try {
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      if (sourceIndex === destinationIndex) return;

      const source = projects.find((keyword) => keyword.id === result.draggableId)!;
      const newProjects = projects;
      newProjects.splice(sourceIndex, 1);
      newProjects.splice(destinationIndex, 0, source);

      setProjects(newProjects);
      onChange(newProjects);

      message.success('슬라이더 순서를 변경했습니다.');
    } catch (e) {
      console.error(e);
      message.error('슬라이더 순서 변경에 실패했습니다.');
    }
  };

  const handleCreateClick = () => {
    openCreateModal({});
  };

  const handleDeteleClick = (id: string) => {
    setProjects((prev) => {
      const newProjects = prev.filter((project) => project.id !== id);

      onChange(newProjects);

      return newProjects;
    });
  };

  const handleCreateBannerSubmit = (values: UserRecommendedProjectResponseModel) => {
    setProjects((prev) => {
      const newProject = {
        ...values,
        id: uuidv4(),
        order: prev.length
      };

      return [...prev, newProject];
    });

    closeCreateModal();
  };

  React.useEffect(() => {
    onChange(projects);
  }, [projects]);

  return (
    <>
      <div>
        <Tooltip
          placement="right"
          title="콘텐츠가 없습니다. 추가하기를 눌러 콘텐츠를 추가해주세요."
          zIndex={1}
          open={projects.length === 0}
        >
          <Button onClick={handleCreateClick}>추가하기</Button>
        </Tooltip>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div css={containerStyle}>
          <Droppable droppableId="subSlider" direction="vertical">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                {projects?.map((project, index) => {
                  return (
                    <Draggable key={project.id} draggableId={project.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style, marginBottom: '4px' }}
                        >
                          <UserRecommendedProjectItem
                            id={project.id}
                            projectId={project.project.id || ''}
                            projectName={project.project.name || ''}
                            projectDescription={project.project.description || ''}
                            projectImageUrl={project.project.verticalImageUrl || project.project.imageUrl || ''}
                            writerName={project.reviewComment.writerName || ''}
                            writerProfileImageUrl={project.reviewComment.writerProfileImageUrl || ''}
                            content={project.reviewComment.content || ''}
                            backgroundColorCode={project.backgroundColorCode}
                            onDeleteClick={handleDeteleClick}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <CreateModal
        modalData={createModal}
        totalProjects={totalProjects.value || []}
        onSubmit={handleCreateBannerSubmit}
      />
    </>
  );
}

const containerStyle = css`
  width: 100%;
`;
