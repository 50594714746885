import axios from 'axios';
import { CreateQuizTemplate, GetQuizTemplateParams, QuizTemplate } from '@src/models/quizTemplate';

export interface ApiResponse<T> {
  data: T;
  message?: string;
  meta?: {
    pagination?: {
      totalCount: number;
      currentPage: number;
      totalPages: number;
      hasMore: boolean;
    };
  };
}

export type PaginatedResponse<T> = ApiResponse<T[]>;

const api = axios.create({
  baseURL: 'https://test.black-story-generator.realworld.to',
  // baseURL: 'http://localhost',
  headers: { 'Content-Type': 'application/json' }
});

export const getQuizTemplates = async (query: GetQuizTemplateParams) => {
  return api.get<PaginatedResponse<QuizTemplate>>('/quiz-templates', { params: query });
};

export const getQuizzes = async (templateId: string) => {
  return api.get(`/quizzes?templateId=${templateId}`);
};

export const createQuizTemplate = async (template: CreateQuizTemplate) => {
  return api.post('/quiz-templates', template);
};
