import { PageHeader } from '@ant-design/pro-components';
import { Button, Col, Divider, Input, Popconfirm, Row, Table, Tabs, Tooltip, message, notification } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { css } from '@emotion/react';
import axios from 'axios';
import DefaultLayout from '@src/components/DefaultLayout';
import { getShortcuts } from '@src/apis/admin-old/getShortcuts';
import { getLocalStorage } from '@src/utils/localStorage';
import { postShortcut } from '@src/apis/admin-old/postShortcut';
import { deleteShortcuts } from '@src/apis/admin-old/deleteShortcut';
import { FormDataType } from '@src/models/shortLink';
import useModalState from '@src/hooks/useModalState';
import { putShortcut } from '@src/apis/admin-old/putShortcut';
import { APP_ENV } from '@src/config';
import { fixedSourceList } from '@src/constants/shortLink';
import InternalForm from './InternalForm';
import ExternalForm from './ExternalForm';
import ModifyShortcutModal from './ModifyShortcutModal';
import CreateShortcutModal from './CreateShortcutModal';

const { Search } = Input;

function getFallbackPostfix(source: string) {
  if (source === 'kakao' || source === 'notion' || source === 'instagram' || !fixedSourceList.includes(source)) {
    return '&$ios_deep_view=realworld_deep_view&$ios_passive_deep_view=realworld_deep_view&$android_passive_deepview=realworld_deep_view&$android_deepview=realworld_deep_view&mt=8';
  }
  return '&$ios_deep_view=realworld_deep_view&$ios_passive_deep_view=realworld_deep_view&$android_passive_deepview=realworld_deep_view&$android_deepview=realworld_deep_view&mt=8';
}

export default function ShortLink() {
  const accessToken = getLocalStorage('token');
  const userEmail = getLocalStorage('userEmail');
  const userName = getLocalStorage('userName');

  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentPersonalPage, setCurrentPersonalPage] = React.useState(0);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [hasNextPersonalPage, setHasNextPersonalPage] = React.useState(true);
  const [data, setData] = React.useState<{ total: object[]; personal: object[] }>({
    total: [],
    personal: []
  });

  const searchKeyword = React.useRef('');
  const shortlinkTypeRef = React.useRef('');

  const {
    openModal: openModifyShortcutModal,
    closeModal: closeModifyShortcutModal,
    modal: modifyShortcutModal
  } = useModalState();
  const {
    openModal: openCreateShortcutModal,
    closeModal: closeCreateShortcutModal,
    modal: createShortcutModal
  } = useModalState();

  const fetchShortLinkData = React.useCallback(async () => {
    const myData = await getShortcuts(accessToken || '', currentPersonalPage, '', userEmail);
    const data = await getShortcuts(accessToken || '', currentPage, searchKeyword.current);

    if (!data || data.length === 0) {
      setHasNextPage(false);
    }

    if (!myData || myData.length === 0) {
      setHasNextPersonalPage(false);
    }

    if (shortlinkTypeRef.current === 'all') {
      if (currentPage === 0) {
        setData((prev) => ({
          ...prev,
          total: data
        }));
      } else {
        setData((prev) => ({
          ...prev,
          total: [...prev.total, ...data]
        }));
      }
    } else if (shortlinkTypeRef.current === 'personal') {
      if (currentPersonalPage === 0) {
        setData((prev) => ({
          ...prev,
          personal: myData
        }));
      } else {
        setData((prev) => ({
          ...prev,
          personal: [...prev.personal, ...myData]
        }));
      }
    } else {
      setData({
        total: data,
        personal: myData
      });
    }
  }, [currentPage, currentPersonalPage]);

  React.useEffect(() => {
    fetchShortLinkData();
  }, [fetchShortLinkData]);

  const handleFinish = async (values: FormDataType, currentLinkType: 'internal' | 'external') => {
    try {
      const {
        originalUrl,
        shortUrl,
        memo,
        utmSource,
        utmSourceEtc,
        utmCampaign,
        utmMedium,
        utmContent,
        flow,
        notificationConfirm
      } = values;

      if (currentLinkType === 'internal') {
        let desktopUrl;

        if (originalUrl.includes('realworld://project')) {
          const id = new URLSearchParams(originalUrl.split('?')[1]).get('projectId');
          desktopUrl = `https://realworld.to/projects/${id}`;
        } else if (originalUrl.includes('realworld://story')) {
          const id = new URLSearchParams(originalUrl.split('?')[1]).get('storyId');
          desktopUrl = `https://realworld.to/stories/${id}`;
        }

        const modifiedUrl = `https://realworld.app.link?deeplink_path=${encodeURIComponent(
          `${originalUrl}${
            // eslint-disable-next-line no-nested-ternary
            notificationConfirm
              ? originalUrl.includes('?')
                ? '&notification_confirm=true'
                : '?notification_confirm=true'
              : ''
          }`
        )}&utm_source=${utmSourceEtc || utmSource}&utm_campaign=${utmCampaign || ''}&utm_medium=${
          utmMedium || ''
        }&utm_content=${utmContent || ''}&$desktop_url=${desktopUrl || ''}&flow=${flow ? 1 : 0}${getFallbackPostfix(
          utmSource
        )}`;

        await postShortcut(shortUrl || '', modifiedUrl, memo, userEmail || '', userName || '');
      } else if (currentLinkType === 'external') {
        let modifiedUrl;
        if (originalUrl.includes('?')) {
          modifiedUrl = `${originalUrl}&utm_source=${utmSourceEtc || utmSource}&utm_campaign=${
            utmCampaign || ''
          }&utm_medium=${utmMedium || ''}&utm_content=${utmContent || ''}`;
        } else {
          modifiedUrl = `${originalUrl}?utm_source=${utmSourceEtc || utmSource}&utm_campaign=${
            utmCampaign || ''
          }&utm_medium=${utmMedium || ''}&utm_content=${utmContent || ''}`;
        }

        await postShortcut(shortUrl || '', modifiedUrl, memo, userEmail || '', userName || '');
      }

      await fetchShortLinkData();

      notification.success({
        message: '단축 링크를 생성했습니다.'
      });

      closeCreateShortcutModal();
    } catch (e) {
      console.error(e);
      if (axios.isAxiosError(e)) {
        notification.error({
          message: (e.response?.data as { message: string }).message
        });
      }
    }
  };

  const handleModify = async (values: FormDataType, currentLinkType: 'internal' | 'external') => {
    try {
      const {
        originalUrl,
        shortUrl,
        memo,
        utmSource,
        utmSourceEtc,
        utmCampaign,
        utmMedium,
        utmContent,
        flow,
        notificationConfirm
      } = values;

      if (currentLinkType === 'internal') {
        let desktopUrl;

        if (originalUrl.includes('realworld://project')) {
          const id = new URLSearchParams(originalUrl.split('?')[1]).get('projectId');
          desktopUrl = `https://realworld.to/projects/${id}`;
        } else if (originalUrl.includes('realworld://story')) {
          const id = new URLSearchParams(originalUrl.split('?')[1]).get('storyId');
          desktopUrl = `https://realworld.to/stories/${id}`;
        }

        const modifiedUrl = `https://realworld.app.link?deeplink_path=${encodeURIComponent(
          `${originalUrl}${
            // eslint-disable-next-line no-nested-ternary
            notificationConfirm
              ? originalUrl.includes('?')
                ? '&notification_confirm=true'
                : '?notification_confirm=true'
              : ''
          }`
        )}&utm_source=${utmSourceEtc || utmSource}&utm_campaign=${utmCampaign || ''}&utm_medium=${
          utmMedium || ''
        }&utm_content=${utmContent || ''}&$desktop_url=${desktopUrl || ''}&flow=${flow ? 1 : 0}${getFallbackPostfix(
          utmSource
        )}`;

        await putShortcut(shortUrl || '', modifiedUrl, memo);
      } else if (currentLinkType === 'external') {
        let modifiedUrl;
        if (originalUrl.includes('?')) {
          modifiedUrl = `${originalUrl}&utm_source=${utmSourceEtc || utmSource}&utm_campaign=${
            utmCampaign || ''
          }&utm_medium=${utmMedium || ''}&utm_content=${utmContent || ''}`;
        } else {
          modifiedUrl = `${originalUrl}?utm_source=${utmSourceEtc || utmSource}&utm_campaign=${
            utmCampaign || ''
          }&utm_medium=${utmMedium || ''}&utm_content=${utmContent || ''}`;
        }

        await putShortcut(shortUrl || '', modifiedUrl, memo);
      }

      await fetchShortLinkData();

      notification.success({
        message: '단축 링크를 수정했습니다.'
      });

      closeModifyShortcutModal();
    } catch (e) {
      console.error(e);
      notification.error({
        message: '단축 링크 수정에 실패했습니다.'
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteShortcuts('', id);

      await fetchShortLinkData();

      notification.success({
        message: '단축 링크 삭제에 성공했습니다.'
      });
    } catch (e) {
      console.error(e);
      notification.success({
        message: '단축 링크 삭제에 실패했습니다.'
      });
    }
  };

  const handleCreateShortcutModalOpen = React.useCallback(
    (record: { redirectTo: string; id: string; note: string }) =>
      openCreateShortcutModal({
        footer: false,
        children: decodeURIComponent(record.redirectTo).includes('realworld://') ? (
          <InternalForm onFinish={(values) => handleFinish(values, 'internal')} isCopy prevData={record} />
        ) : (
          <ExternalForm onFinish={(values) => handleFinish(values, 'external')} isCopy prevData={record} />
        )
      }),
    []
  );

  const handleModifyShortcutModalOpen = React.useCallback(
    (record: { redirectTo: string; id: string; note: string }) =>
      openModifyShortcutModal({
        footer: false,
        children: decodeURIComponent(record.redirectTo).includes('realworld://') ? (
          <InternalForm onFinish={(values) => handleModify(values, 'internal')} isModify prevData={record} />
        ) : (
          <ExternalForm onFinish={(values) => handleModify(values, 'external')} isModify prevData={record} />
        )
      }),
    []
  );

  const myLinkColumn: ColumnsType<object> = [
    {
      key: 'redirectTo',
      dataIndex: 'redirectTo',
      title: '원래 주소',
      render: (value) => {
        return (
          <Tooltip title={value}>
            <div
              style={{
                width: '200px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              <a href={value}>{value}</a>
            </div>
          </Tooltip>
        );
      }
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: '단축 주소',
      render: (value) => (
        <div
          role="button"
          onClick={async () => {
            await window.navigator.clipboard.writeText(
              `https://${APP_ENV === 'development' ? 'test.rwd.to' : 'rwd.to'}/${value}`
            );

            message.success(
              `[https://${APP_ENV === 'development' ? 'test.rwd.to' : 'rwd.to'}/${value}] 클립보드에 복사되었습니다.`
            );
          }}
          onKeyDown={() => {
            console.log('keydown');
          }}
          style={{ display: 'inline-block', cursor: 'pointer' }}
          tabIndex={-1}
        >
          <span style={{ marginRight: '4px' }}>
            https://{APP_ENV === 'development' ? 'test.rwd.to' : 'rwd.to'}/{value}
          </span>
          <CopyOutlined />
        </div>
      )
    },
    {
      key: 'note',
      dataIndex: 'note',
      title: '메모'
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: '생성일시',
      render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      key: 'hits',
      dataIndex: 'hits',
      title: '클릭 수'
    },
    {
      title: '동작',
      render: (record) => {
        return (
          <div css={ControllerContainer}>
            <Button
              onClick={() => handleModifyShortcutModalOpen(record)}
              size="small"
              style={{
                border: 'none',
                backgroundColor: '#ffc524',
                color: '#fff'
              }}
            >
              수정
            </Button>
            <Button size="small" onClick={() => handleCreateShortcutModalOpen(record)}>
              복사
            </Button>
            <Popconfirm
              title="정말 삭제하시겠어요?"
              onConfirm={() => handleDelete(record.id)}
              okText="삭제"
              okButtonProps={{ danger: true }}
              cancelText="닫기"
            >
              <Button size="small" danger>
                삭제
              </Button>
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  const totalLinkColumn: ColumnsType<object> = [
    {
      key: 'redirectTo',
      dataIndex: 'redirectTo',
      title: '원래 주소',
      render: (value) => {
        return (
          <Tooltip title={value}>
            <div
              style={{
                width: '200px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              <a href={value}>{value}</a>
            </div>
          </Tooltip>
        );
      }
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: '단축 주소',
      render: (value) => (
        <div
          role="button"
          onClick={async () => {
            await window.navigator.clipboard.writeText(
              `https://${APP_ENV === 'development' ? 'test.rwd.to' : 'rwd.to'}/${value}`
            );

            message.success(
              `[https://${APP_ENV === 'development' ? 'test.rwd.to' : 'rwd.to'}/${value}] 클립보드에 복사되었습니다.`
            );
          }}
          onKeyDown={() => {
            console.log(
              `[https://${APP_ENV === 'development' ? 'test.rwd.to' : 'rwd.to'}/${value}] 클립보드에 복사되었습니다.`
            );
          }}
          style={{ display: 'inline-block', cursor: 'pointer' }}
          tabIndex={-1}
        >
          <span style={{ marginRight: '4px' }}>
            https://{APP_ENV === 'development' ? 'test.rwd.to' : 'rwd.to'}/{value}
          </span>
          <CopyOutlined />
        </div>
      )
    },
    {
      key: 'note',
      dataIndex: 'note',
      title: '메모'
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: '생성일시',
      render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      key: 'creatorName',
      dataIndex: 'creatorName',
      title: '생성자'
    },
    {
      key: 'hits',
      dataIndex: 'hits',
      title: '클릭 수'
    },
    {
      title: '동작',
      render: (record) => {
        if (record.creatorId === 'RealWorld') return '';
        return (
          <div css={ControllerContainer}>
            <Button
              onClick={() => handleModifyShortcutModalOpen(record)}
              size="small"
              style={{
                border: 'none',
                backgroundColor: '#ffc524',
                color: '#fff'
              }}
            >
              수정
            </Button>
            <Button onClick={() => handleCreateShortcutModalOpen(record)} size="small">
              복사
            </Button>
            <Popconfirm
              title="정말 삭제하시겠어요?"
              onConfirm={() => handleDelete(record.id)}
              okText="삭제"
              okButtonProps={{ danger: true }}
              cancelText="닫기"
            >
              <Button size="small" danger>
                삭제
              </Button>
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  const items = [
    {
      key: 'internal',
      label: '앱 내 링크',
      children: <InternalForm onFinish={(values) => handleFinish(values, 'internal')} />
    },
    {
      key: 'external',
      label: '외부 링크',
      children: <ExternalForm onFinish={(values) => handleFinish(values, 'external')} />
    }
  ];

  const shortlinkTabItems = [
    {
      key: 'all',
      label: '전체 단축링크',
      children: (
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={4} style={{ display: 'inline-block' }}>
              전체 단축링크
            </Title>
            <Search
              enterButton
              onSearch={async (value) => {
                searchKeyword.current = value;
                setData((prev) => ({
                  ...prev,
                  total: prev.total
                }));
                setCurrentPage(0);
                setHasNextPage(true);
                await fetchShortLinkData();
              }}
              placeholder="검색"
              style={{ display: 'inline-block', width: '40%' }}
            />
          </div>
          <Table
            dataSource={data.total}
            columns={totalLinkColumn}
            pagination={false}
            scroll={{
              x: 1400
            }}
            rowKey="id"
          />
          {hasNextPage && (
            <div style={{ margin: '16px 0', textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => {
                  setCurrentPage((prev) => prev + 1);
                  shortlinkTypeRef.current = 'all';
                }}
              >
                더보기
              </Button>
            </div>
          )}
        </Col>
      )
    },
    {
      key: 'personal',
      label: '내 단축링크',
      children: (
        <Col span={24}>
          <div css={HeaderTitleContainer}>
            <Title level={4}>내 단축링크</Title>
            <div>
              <Button
                type="primary"
                onClick={() =>
                  openCreateShortcutModal({
                    children: <Tabs items={items} />
                  })
                }
                icon={<PlusOutlined />}
              >
                추가
              </Button>
            </div>
          </div>

          <Table
            dataSource={data.personal}
            columns={myLinkColumn}
            pagination={false}
            scroll={{ x: 1400 }}
            rowKey="id"
          />
          {hasNextPersonalPage && (
            <div style={{ margin: '16px 0', textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => {
                  setCurrentPersonalPage((prev) => prev + 1);
                  shortlinkTypeRef.current = 'personal';
                }}
              >
                더보기
              </Button>
            </div>
          )}
        </Col>
      )
    }
  ];

  return (
    <>
      <DefaultLayout>
        <PageHeader title="단축링크 관리" subTitle="단축링크를 관리합니다." style={{ margin: '16px 20px 0px 20px' }} />
        <Divider />
        <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
          <Row gutter={[0, 48]}>
            <Tabs items={shortlinkTabItems} style={{ width: '100%' }} />
          </Row>
        </Content>
      </DefaultLayout>
      <CreateShortcutModal modalData={createShortcutModal} />
      <ModifyShortcutModal modalData={modifyShortcutModal} />
    </>
  );
}

const ControllerContainer = css`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 8px;
`;

const HeaderTitleContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
