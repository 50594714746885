import {
  ImageSlideBannerListResponseModel,
  RecommendedKeywordListResponseModel,
  SectionType,
  UserRecommendedProjectListResponseModel
} from '@uniquegood/realworld-admin-interface';
import { Button, Divider, Form, Input, Select, Tooltip, message } from 'antd';
import React from 'react';
import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { useAsyncFn } from 'react-use';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import DefaultLayout from '@src/components/DefaultLayout';
import { mainPageSectionApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { sectionTypeToString } from '@src/constants/section';
import ImageSliderBannerList from '../Form/ImageSliderBannerList';
import RecommendedKeywordList from '../Form/RecommendedList';
import UserRecommendedProjectList from '../Form/UserRecommendedProjectList';

type FormData = {
  name: string;
  type: SectionType;
  isDisabled: boolean;
  sectionData: ImageSlideBannerListResponseModel;
  order: number;
};

export default function ModifyPage() {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const { groupId, sectionId } = useParams<{ groupId: string; sectionId: string }>();

  const [sectionDetailData, fetchSectionDetailData] = useAsyncFn(async () => {
    try {
      if (!groupId || !sectionId) return undefined;

      const { data } = await mainPageSectionApi.getSectionById(groupId, sectionId, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return data.data;
    } catch (e) {
      console.error(e);

      return undefined;
    }
  }, [groupId, sectionId]);

  const type = sectionDetailData.value?.type;

  const handleSubmit = async (values: FormData) => {
    try {
      if (!groupId || !sectionId) return;

      if (!sectionDetailData.value) return;

      const { id, ...rest } = sectionDetailData.value;

      const { data } = await mainPageSectionApi.updateMainPageSection(
        groupId,
        sectionId,
        {
          ...rest,
          ...values
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        message.success('섹션 데이터를 변경했습니다.');
      }
    } catch (e) {
      console.error(e);

      message.error('섹션 데이터 변경에 실패했습니다.');
    }
  };

  React.useEffect(() => {
    fetchSectionDetailData();
  }, [fetchSectionDetailData]);

  React.useEffect(() => {
    const nextSectionData = {
      recommendedKeywords: (
        sectionDetailData.value?.sectionData as RecommendedKeywordListResponseModel
      )?.recommendedKeywords?.map((keyword, index) => {
        const data = {
          keyword: keyword.keyword,
          order: index,
          projectIds: keyword.projects?.map((project) => project.id)
        };

        return data;
      })
    };

    form.setFieldsValue({
      ...sectionDetailData.value,
      sectionData: nextSectionData
    });
  }, [sectionDetailData.value]);

  return (
    <DefaultLayout>
      <PageHeader
        title="섹션 데이터 수정"
        subTitle="섹션 데이터를 수정합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="name" label="이름">
            <Input placeholder="섹션 이름을 입력해주세요." />
          </Form.Item>
          <Form.Item name="type" label="타입">
            <Select
              placeholder="섹션 타입을 선택해주세요."
              options={Object.keys(SectionType).map((type) => ({
                label: sectionTypeToString[type as SectionType],
                value: type
              }))}
            />
          </Form.Item>
          <Form.Item name="isDisabled" label="활성화 여부">
            <Select
              options={[
                { label: '활성화', value: false },
                { label: '비활성화', value: true }
              ]}
            />
          </Form.Item>
          <Form.Item name="sectionData" style={{ display: 'none' }} />
        </Form>
        {type === SectionType.ImageSlideBanners && (
          <ImageSliderBannerList
            imageBanners={(sectionDetailData?.value?.sectionData as ImageSlideBannerListResponseModel)?.imageBanners}
            onChange={(banners) => {
              form.setFieldValue('sectionData', {
                imageBanners: banners?.map((banner, index) => {
                  const { id, ...rest } = banner;

                  return {
                    ...rest,
                    order: index
                  };
                })
              });
            }}
          />
        )}
        {type === SectionType.RecommendedKeywords && (
          <RecommendedKeywordList
            recommendedKeywords={
              (sectionDetailData.value?.sectionData as RecommendedKeywordListResponseModel).recommendedKeywords || []
            }
            onChange={(keywords) => {
              form.setFieldValue('sectionData', {
                recommendedKeywords: keywords?.map((keyword, index) => {
                  const { id, ...rest } = keyword;

                  const data = {
                    keyword: rest.keyword,
                    order: index,
                    projectIds: rest.projects?.map((project) => project.id)
                  };

                  return data;
                })
              });
            }}
          />
        )}
        {type === SectionType.PlayersRecommendGames && (
          <UserRecommendedProjectList
            userRecommendedProjects={
              (sectionDetailData.value?.sectionData as UserRecommendedProjectListResponseModel).recommendedProjects ||
              []
            }
            onChange={(projects) => {
              form.setFieldValue('sectionData', {
                recommendedProjects: projects?.map((project, index) => {
                  return {
                    backgroundColorCode: project.backgroundColorCode,
                    projectId: project.project.id,
                    reviewCommentId: project.reviewComment.id,
                    order: index
                  };
                })
              });
            }}
          />
        )}
        <div css={controllerContainerStyle}>
          <Tooltip
            placement="left"
            open
            title="저장 버튼을 눌러 변경된 사항을 저장해주세요."
            overlayInnerStyle={{
              width: 'max-content',
              position: 'absolute',
              top: 0,
              right: 0,
              transform: 'translateY(-50%)'
            }}
          >
            <Button onClick={form.submit} type="primary">
              저장
            </Button>
          </Tooltip>
        </div>
      </Content>
    </DefaultLayout>
  );
}

const controllerContainerStyle = css`
  width: 100%;
  padding: 8px 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  text-align: right;
`;
