/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloseOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Card, Tooltip } from 'antd';

type ProjectItemProps = {
  id: string;
  name: string;
  imageUrl: string;
  onCloseClick: (id: string) => unknown;
};

export default function ProjectItem({ id, name, imageUrl, onCloseClick }: ProjectItemProps) {
  const handleCloseClick = () => {
    onCloseClick(id);
  };

  return (
    <Card
      css={containerStyle}
      style={{
        width: '150px',
        height: '100%'
      }}
      bodyStyle={{
        padding: 0,
        borderRadius: '8px',
        overflow: 'hidden'
      }}
    >
      <div style={{ width: '100%', height: '200px' }}>
        <img
          src={imageUrl}
          alt="슬라이더 아이템 썸네일"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Tooltip title={name}>
          <h3
            style={{
              width: '100%',
              margin: 0,
              padding: '8px',
              textAlign: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {name}
          </h3>
        </Tooltip>
      </div>
      <div
        role="presentation"
        onClick={handleCloseClick}
        style={{ position: 'absolute', top: '8px', right: '8px', color: '#777' }}
      >
        <CloseOutlined />
      </div>
    </Card>
  );
}

const containerStyle = css`
  width: 100%;
`;
