import { useAsyncFn } from 'react-use';
import React from 'react';
import { Card, Tag, message } from 'antd';
import { PreferenceResponseModel } from '@uniquegood/realworld-admin-interface';
import { css } from '@emotion/react';
import { PlusCircleOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { miscApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import Button from '@src/components/Button';
import useModalState from '@src/hooks/useModalState';
import TemplateContainer from './TemplateContainer';
import ModifyPreferenceModal from './ModifyPreferenceModal';

interface TabChildrenProps {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  isActivate: boolean;
  refetch: () => unknown;
}

export default function TabChildren({ id, name, description, imageUrl, isActivate, refetch }: TabChildrenProps) {
  const accessToken = getLocalStorage('token');

  const [isCreate, setIsCreate] = React.useState(false);

  const [detailData, fetchDetailData] = useAsyncFn(async () => {
    const { data } = await miscApi.getPreferenceTemplateList(id, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return data.data;
  }, []);

  const { openModal, closeModal, modal } = useModalState();

  const handleAddClick = async () => {
    setIsCreate(true);
  };

  const handleModify = async (values: PreferenceResponseModel) => {
    try {
      const { name, description, imageUrl, isActivate } = values;

      const { data } = await miscApi.updatePreference(
        id,
        {
          name,
          description,
          imageUrl,
          isActivate
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        await refetch();
        closeModal();
        message.success('취향을 수정했습니다.');
      }
    } catch (e) {
      console.error(e);
      message.error('취향 수정에 실패했습니다.');
    }
  };

  React.useEffect(() => {
    fetchDetailData();
  }, [fetchDetailData]);

  return (
    <>
      <Card bodyStyle={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, display: 'flex' }}>
          <div style={{ width: '200px' }}>
            <a href={imageUrl} target="blank">
              <img src={imageUrl} alt="템플릿 이미지" style={{ width: '100%' }} />
            </a>
          </div>
          <div style={{ marginLeft: '16px' }}>
            <div>설명: {description}</div>
            <div>상태: {isActivate ? <Tag color="green">활성화</Tag> : <Tag color="gray">비활성화</Tag>}</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'self-end'
          }}
        >
          <Button onClick={() => openModal({})}>수정</Button>
          <Button type={isCreate ? 'default' : 'primary'} onClick={handleAddClick} style={{ marginTop: '16px' }}>
            템플릿 추가
          </Button>
        </div>
      </Card>
      <div>
        {isCreate && <TemplateContainer id={id} isCreate setIsCreate={setIsCreate} refetch={fetchDetailData} />}
        {detailData.value &&
          detailData.value.length > 0 &&
          detailData.value?.map((item) => (
            <TemplateContainer
              key={item.id}
              id={id}
              templateId={item.id || ''}
              projects={item.projects || []}
              title={item.title || ''}
              refetch={fetchDetailData}
            />
          ))}
        {detailData.value && detailData.value.length === 0 && !isCreate && (
          <button type="button" css={clickAreaStyle} onClick={handleAddClick}>
            <PlusCircleOutlined style={{ fontSize: '48px', color: '#888' }} />
            <Title level={5} style={{ marginTop: '16px', color: '#555' }}>
              현재 생성된 템플릿이 없습니다. 여기를 눌러 템플릿을 추가해보세요.
            </Title>
          </button>
        )}
      </div>
      <ModifyPreferenceModal
        modalData={modal}
        onFinish={handleModify}
        originData={{ id, description, name, imageUrl, isActivate }}
      />
    </>
  );
}

const clickAreaStyle = css`
  width: 100%;
  padding: 24px;
  margin-top: 24px;
  border: 2px dashed #aaa;
  border-radius: 16px;
  text-align: center;
  background: none;
  outline: none;
  cursor: pointer;
`;
