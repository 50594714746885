import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Select, Input, Divider, Result, Typography, Popconfirm, Modal, Descriptions } from 'antd';
import { CheckCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import dayjs, { Dayjs } from 'dayjs';
import { ADMIN_SERVER_HOST } from '@src/config';
import { downloadExcel } from '@src/utils/excel';
import { giftCodeDataColumnKeyLabel } from '@src/constants/giftCodeDataColumnKey';
import { giftCodePurposeLabel } from '@src/constants/giftCodePurpose';
import { GiftCodePurpose } from '@src/models/giftCodePurpose';
import { getGiftCodeUrl } from '@src/utils/giftCode';
import DefaultLayout from '@src/components/DefaultLayout';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import Button from '@src/components/Button';
import { getLocalStorage } from '@src/utils/localStorage';

interface ConditionType {
  projectId: string;
  count: number;
  price: number;
  keyword: string;
  purpose: string;
}

const { Title } = Typography;

function GiftCardCreatePage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [form] = Form.useForm();

  const [contentTitle, setContentTitle] = useState('');
  const [conditions, setConditions] = useState<ConditionType>();
  const [createdGiftCodes, setCreatedGiftCodes] = useState([]);
  const [isCreateSuccess, setIsCreateSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const groupCreatedAt = useRef<Dayjs>();

  useEffect(() => {
    getContentInfo();
  }, []);

  // 기프트카드 생성할 콘텐츠명을 알기 위한 API 요청
  async function getContentInfo() {
    try {
      const res = await axios.get(`https://${ADMIN_SERVER_HOST}/manage/api/projects/${id}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorage('token')}`
        }
      });
      setContentTitle(res.data.name);
    } catch (err) {
      console.error(err);
    }
  }

  // 기프트카드 생성 API 요청 함수
  async function CreateGiftCode(values: ConditionType) {
    // 기프트카드 생성시 사용자 입력값 동기화해서 가지고 있어야 함 -> 엑셀 파일 만들기, 생성된 기프트카드 정보 보여주기
    setConditions({
      ...values
    });

    try {
      const res = await axios.post(
        `https://${ADMIN_SERVER_HOST}/manage/api/giftCards`,
        {
          ...values, // purpose: [Produce, Group, Etc]
          projectId: id
        },
        {
          headers: {
            Authorization: `Bearer ${getLocalStorage('token')}`
          }
        }
      );
      setCreatedGiftCodes(res.data.giftCodes);
      handleCreateState();
      groupCreatedAt.current = dayjs(res.data.groupCreatedAt);
    } catch (err) {
      console.error(err);
      setIsCreateSuccess(false);
    }
  }

  // 생성된 기프트카드 정보를 담은 엑셀 파일 생성, 다운로드
  function handleExcelDownload() {
    if (conditions === undefined) {
      return;
    }
    downloadExcel({
      keys: ['giftCode', 'purpose', 'price', 'keyword', 'createdAt', 'giftCodeUrl'],
      header: { ...giftCodeDataColumnKeyLabel, giftCodeUrl: '기프트카드 URL' },
      data: createdGiftCodes.map((giftCode) => ({
        giftCode,
        purpose: giftCodePurposeLabel[conditions.purpose as GiftCodePurpose],
        price: String(conditions.price),
        keyword: conditions.keyword,
        createdAt: `${groupCreatedAt.current?.format('YYYY-MM-DD') || '-'}`,
        giftCodeUrl: getGiftCodeUrl(giftCode)
      })),
      fileName: `${contentTitle}-${conditions.keyword}`,
      type: 'csv'
    });
  }

  // 기프트카드 생성 조건 설정 완료시
  const onFinish = (fieldsValue: ConditionType) => {
    if (id === undefined) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
      return;
    }

    // 기프트카드 생성 API 요청 함수 호출
    CreateGiftCode({
      ...fieldsValue,
      price: Number(fieldsValue.price),
      count: Number(fieldsValue.count)
    });
  };

  // 기프트카드 API 요청 후 상태 업데이트
  function handleCreateState() {
    setIsCreateSuccess(true);
    setIsModalOpen(true);
  }

  return (
    <DefaultLayout>
      <PageHeader
        title="기프트카드 신규 발급 페이지"
        subTitle="기프트카드를 생성합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        {/* 기프트카드 생성할 콘텐츠명 */}
        <Title level={4} mark>
          {contentTitle}
        </Title>

        {/* 기프트카드 조건 생성 폼 */}
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="inline"
          style={{
            border: '1px solid gray',
            padding: '20px',
            marginBottom: '16px',
            justifyContent: 'flex-end'
          }}
          onFinish={(e) => onFinish(e)}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '12px 0px',
              width: '100%'
            }}
          >
            <Form.Item label="발급목적" name="purpose" rules={[{ required: true, message: '발급목적을 선택하세요!' }]}>
              <Select placeholder="발급목적" allowClear>
                <Select.Option key="produce" value="Produce">
                  판매용
                </Select.Option>
                <Select.Option key="group" value="Group">
                  단체용
                </Select.Option>
                <Select.Option key="etc" value="Etc">
                  기타
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="키워드" name="keyword" rules={[{ required: true, message: '키워드를 입력하세요!' }]}>
              <Input placeholder="ex) 유니크굿컴퍼니" />
            </Form.Item>
            <Form.Item label="발급 단가" name="price" rules={[{ required: true, message: '발급 단가를 입력하세요!' }]}>
              <Input placeholder="ex) 3000" />
            </Form.Item>

            <Form.Item label="발급 수량" name="count" rules={[{ required: true, message: '발급 수량을 입력하세요!' }]}>
              <Input placeholder="ex) 30" />
            </Form.Item>
          </div>

          {/* 버튼 영역 */}
          <Popconfirm
            title="위 정보로 기프트카드를 생성 하시겠습니까?"
            okText="네"
            cancelText="아니오"
            placement="bottomRight"
            icon={<InfoCircleTwoTone />}
            onConfirm={() => {
              form.submit();
            }}
          >
            <Button key="issue-button" type="primary">
              발급
            </Button>
          </Popconfirm>
        </Form>
      </Content>

      {/* 기프트카드 정상 생성시 */}
      {isCreateSuccess ? (
        <Result
          status="success"
          title="기프트카드를 정상적으로 생성했습니다."
          subTitle="아래 버튼을 눌러 생성한 기프트카드 엑셀 파일을 다운로드 할 수 있습니다."
          icon={<CheckCircleOutlined />}
          extra={[
            <Button type="primary" key="download" onClick={handleExcelDownload} size="middle" success>
              엑셀 파일 다운로드
            </Button>
          ]}
        >
          {/* 생성된 기프트카드 정보 */}
          <Descriptions title="생성한 기프트카드 정보" size="small" bordered column={4}>
            <Descriptions.Item label="발급 목적">
              {giftCodePurposeLabel[conditions?.purpose as GiftCodePurpose]}
            </Descriptions.Item>
            <Descriptions.Item label="키워드">{conditions?.keyword}</Descriptions.Item>
            <Descriptions.Item label="발급 단가">{conditions?.price}</Descriptions.Item>
            <Descriptions.Item label="발급 수량">{conditions?.count}</Descriptions.Item>
          </Descriptions>
        </Result>
      ) : null}

      <Modal
        title="기프트카드를 정상적으로 생성했습니다."
        centered
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="ok" type="primary" onClick={() => setIsModalOpen(false)}>
            확인
          </Button>
        ]}
      />
    </DefaultLayout>
  );
}

export default GiftCardCreatePage;
