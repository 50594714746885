import { Button, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Modal from 'antd/es/modal/Modal';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import {
  ButtonType,
  PointAction,
  PointEventFilterType,
  PointEventType,
  PointFilterType,
  UserPointsResponseModel,
  UserPointsResponseModelPaginatedResponse
} from '@uniquegood/realworld-admin-interface/dist';
import dayjs from 'dayjs';
import useModalState from '@src/hooks/useModalState';
import { pointApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { PointActionLabel } from '@src/constants/point';
import { PointLogDetailModal } from './PointLogDetailModal';
import { ForcedRefundCheckModal } from './ForcedRefundCheckModal';
import { CancelCheckModal } from './CancelCheckModal';

interface DataType {
  key: React.Key;
  id: string;
  type: string;
  value: PointAction;
  content: string;
  processedAt: string;
  userId: string;
  pointEventType: PointEventType;
  buttonType: ButtonType;
}

interface AllProps {
  data: UserPointsResponseModelPaginatedResponse;
  isLoading: boolean;
  refetch?: {
    setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
    setFilter?: React.Dispatch<React.SetStateAction<PointEventFilterType[]>>;
    setUsername?: React.Dispatch<React.SetStateAction<string>>;
  };
  refetchFn?: () => unknown;
  parentRefetchFn?: () => unknown;
  pageSize?: number;
}

export function PointLogDataTable({ data, isLoading, refetch, refetchFn, parentRefetchFn, pageSize = 20 }: AllProps) {
  const accessToken = getLocalStorage('token');

  const [selectedData, setSelectedData] = React.useState({
    userId: '',
    pointEventId: '',
    pointEventType: '' as PointEventType
  });

  // 츄로 상세 모달
  const { openModal: openDetailModal, closeModal: closeDetailModal, modal: detailModal } = useModalState();
  // 포인트 취소 모달
  const { openModal: openCancelModal, closeModal: closeCancelModal, modal: cancelModal } = useModalState();
  // 강제 환불 확인 모달
  const {
    openModal: openForcedRefundCheckModal,
    closeModal: closeForcedRefundCheckModal,
    modal: forcedRefundCheckModal
  } = useModalState();

  const handleCancelClick = (id: string) => {
    setSelectedData({
      ...selectedData,
      pointEventId: id
    });
    openCancelModal({
      title: '정말 삭제하시겠습니까?',
      transitionName: ''
    });
  };

  const handleRefundClick = async (userId: string, pointEventId: string) => {
    try {
      setSelectedData({
        ...selectedData,
        userId,
        pointEventId
      });
      const { data } = await pointApi.checkUserPointUsed(userId, pointEventId, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (data.data?.isRefundable) {
        openForcedRefundCheckModal({
          title: '어드민에서 환불할 수 없습니다.',
          transitionName: ''
        });
      } else {
        openForcedRefundCheckModal({
          title: '어드민에서 환불할 수 없습니다.',
          transitionName: ''
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '츄로 타입',
      dataIndex: 'type',
      key: 'type',
      render: (value: PointAction, record) => {
        const pointValue = record.value;

        if (typeof pointValue === 'string') {
          const splitted = pointValue.split(' ');

          const stringToNumberPoint = splitted.reduce((acc, cur) => acc + Number(cur), 0);

          if (value === PointAction.Purchased) {
            return <Tag color="#ff6565">{PointActionLabel[value]}</Tag>;
          }
          if (value === PointAction.Expired) {
            return <Tag color="#aaa">{PointActionLabel[value]}</Tag>;
          }
          if (Number(stringToNumberPoint) > 0) {
            return <Tag color="red">{PointActionLabel[value]}</Tag>;
          }
          if (Number(stringToNumberPoint) < 0) {
            return <Tag color="blue">{PointActionLabel[value]}</Tag>;
          }
          return <Tag>{PointActionLabel[value]}</Tag>;
        }

        if (value === PointAction.Purchased) {
          return <Tag color="#ff6565">{PointActionLabel[value]}</Tag>;
        }
        if (value === PointAction.Expired) {
          return <Tag color="#aaa">{PointActionLabel[value]}</Tag>;
        }
        if (Number(record.value) > 0) {
          return <Tag color="red">{PointActionLabel[value]}</Tag>;
        }

        if (Number(record.value) < 0) {
          return <Tag color="blue">{PointActionLabel[value]}</Tag>;
        }
        return <Tag>{PointActionLabel[value]}</Tag>;
      }
    },
    {
      title: '츄로 값',
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: '원화 환산 가격',
      key: 'originalPrice',
      render: (_, record) => {
        const splitted = String(record.value).split(' ');

        if (splitted.length === 1) {
          return Number(record.value) * 15;
        }
        return splitted.reduce((acc, cur) => Number(acc) + Number(cur), 0) * 15;
      }
    },
    {
      title: '유저 닉네임',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: '처리 내용',
      dataIndex: 'content',
      key: 'content'
    },
    {
      title: '처리 시점',
      dataIndex: 'processedAt',
      key: 'processedAt'
    },
    {
      title: '처리 관리자',
      dataIndex: 'managerName',
      key: 'managerName'
    },
    {
      title: '동작',
      dataIndex: 'action',
      key: 'action',
      onCell: () => {
        return {
          onClick: (e) => e.stopPropagation()
        };
      },
      render: (value, record) => {
        switch (record.buttonType) {
          case 'Cancel':
            return (
              <Button danger onClick={() => handleCancelClick(record.id)}>
                취소
              </Button>
            );
          case 'Refund':
            return (
              <Button
                onClick={() => handleRefundClick(record.userId, record.id)}
                style={{ backgroundColor: 'crimson', color: '#fff', border: 'none' }}
              >
                환불
              </Button>
            );
          case 'None':
          default:
            return null;
        }
      }
    }
  ];

  const renderPoint = (item: UserPointsResponseModel) => {
    switch (item.pointType) {
      case PointFilterType.All:
        return `${item.userUsedFreePoint} ${item.userUsedPaidPoint}`;
      case PointFilterType.Free:
        return item.userUsedFreePoint;
      case PointFilterType.Paid:
        return item.userUsedPaidPoint;
      default:
        return false;
    }
  };

  const source = data.data?.map(
    (item, index) =>
      ({
        key: index,
        id: item.pointEventId,
        type: item.pointEventAction,
        pointEventType: item.pointEventType,
        value: renderPoint(item),
        content: item.eventLog,
        processedAt: dayjs(item.processedAt).format('YYYY.MM.DD HH:mm:ss'),
        managerName: item.managerName,
        userName: item.userName,
        userId: item.userId,
        buttonType: item.buttonType
      } as unknown as DataType)
  );

  return (
    <>
      <Content>
        <Table
          dataSource={source}
          columns={columns}
          onRow={(record) => ({
            onClick: () => {
              const { id, userId, pointEventType } = record;

              setSelectedData({
                userId,
                pointEventId: id,
                pointEventType
              });

              openDetailModal({
                title: '츄로 상세',
                transitionName: ''
              });
            }
          })}
          pagination={{
            showSizeChanger: false,
            pageSize,
            total: data.count,
            current: data.pageIndex,
            onChange: (page) => refetch?.setCurrentPage?.(page)
          }}
          loading={isLoading}
        />
      </Content>
      <Modal {...detailModal} width={1000} okText="확인" cancelText="닫기">
        <PointLogDetailModal
          userId={selectedData.userId}
          pointEventId={selectedData.pointEventId}
          pointEventType={selectedData.pointEventType}
          refetchFn={refetchFn}
        />
      </Modal>
      <CancelCheckModal
        modal={cancelModal}
        closeModal={closeCancelModal}
        pointEventId={selectedData.pointEventId}
        refetchFn={refetchFn}
        parentRefetchFn={parentRefetchFn}
      />
      <ForcedRefundCheckModal
        modal={forcedRefundCheckModal}
        closeModal={closeForcedRefundCheckModal}
        selectedData={selectedData}
        refetchFn={refetchFn}
      />
    </>
  );
}
