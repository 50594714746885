import { Button, Card, Input, Select, Typography } from 'antd';
import { ProjectLite, SectionDisplayType } from '@uniquegood/realworld-admin-interface';
import { CloseOutlined, HolderOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Section } from '@src/models/mainPage';
import { sectionDisplayTypeLabel } from '@src/constants/mainPage';
import Projects, { useProjects } from './Projects';
import OriginalProjectSelect from './OriginalProjectSelect';

const { Option } = Select;
const { Text } = Typography;
interface Props {
  data: Section;
  setData: (
    props:
      | { key: 'name'; value: string }
      | { key: 'displayType'; value: SectionDisplayType }
      | { key: 'projects'; value: ProjectLite[] }
  ) => void;
  onClickRemove: () => unknown;
  originalProjects: ProjectLite[];
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

function MainCurationSection({ data, setData, onClickRemove, originalProjects, dragHandleProps }: Props) {
  const { onItemAdd: onProjectItemAdd, ...projectsProps } = useProjects(data.projects);
  const [name, setName] = useState(data.name || '');

  useEffect(() => {
    setData({ key: 'projects', value: projectsProps.projectItems });
  }, [projectsProps.projectItems, setData]);

  return (
    <Card
      title={
        <>
          <HolderOutlined style={{ padding: '2px', marginRight: '4px' }} {...dragHandleProps} />
          {name}
        </>
      }
      extra={<Button shape="circle" onClick={onClickRemove} icon={<CloseOutlined />} />}
    >
      <div>
        <Text strong>섹션 이름</Text>
        <Input
          defaultValue={data.name || ''}
          onChange={(e) => {
            const nextValue = e.target.value;
            setData({ key: 'name', value: nextValue });
            setName(nextValue);
          }}
        />
      </div>

      <div>
        <Text strong>섹션 타입</Text>
        <Select
          css={{ width: '100%' }}
          defaultValue={data.displayType}
          onChange={(sectionDisplayType: SectionDisplayType) =>
            setData({ key: 'displayType', value: sectionDisplayType })
          }
        >
          {Object.values(SectionDisplayType).map((v: SectionDisplayType) => (
            <Option key={v} value={v}>
              {sectionDisplayTypeLabel[v]}
            </Option>
          ))}
        </Select>
      </div>

      <Projects {...projectsProps} />
      <OriginalProjectSelect projects={originalProjects} onProjectItemAdd={onProjectItemAdd} />
    </Card>
  );
}

export default MainCurationSection;
