import { ProjectCommentResponseModel } from '@uniquegood/realworld-admin-interface';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useAsync } from 'react-use';
import React from 'react';
import { getLocalStorage } from '@src/utils/localStorage';
import { projectCommentApi } from '@src/apis/admin';
import UserDefaultImage from '../../assets/userDefaultImg.svg';

type CommentTableProps = {
  projectId: string;
  onCommentSelect?: (comment: ProjectCommentResponseModel) => unknown;
};

export default function CommentTable({ projectId, onCommentSelect }: CommentTableProps) {
  const accessToken = getLocalStorage('token');

  const [currentPage, setCurrentPage] = React.useState(1);

  const projectCommentsData = useAsync(async () => {
    if (!projectId) return undefined;

    try {
      const { data } = await projectCommentApi.getProjectComments(projectId, currentPage, 10, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return data.data;
    } catch (e) {
      console.error(e);

      return undefined;
    }
  }, [projectId, currentPage]);

  const columns: ColumnsType<ProjectCommentResponseModel> = [
    {
      key: 'writerName',
      dataIndex: 'writerName',
      title: '작성자',
      render: (value, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={record.writerProfileImageUrl || UserDefaultImage}
            alt="유저 프로필 이미지"
            style={{ width: '20px', height: '20px', marginRight: '4px', borderRadius: '50%' }}
          />
          <span>{value}</span>
        </div>
      )
    },
    {
      key: 'content',
      dataIndex: 'content',
      title: '내용'
    }
  ];
  return (
    <Table
      dataSource={projectCommentsData.value?.data || []}
      columns={columns}
      rowSelection={{
        type: 'radio',
        onSelect: (record, selected, selectedRows) => {
          onCommentSelect?.(selectedRows[0]);
        }
      }}
      pagination={{
        showSizeChanger: false,
        total: projectCommentsData.value?.count,
        onChange: (page) => {
          setCurrentPage(page);
        }
      }}
      scroll={{
        x: 1400
      }}
      rowKey="id"
    />
  );
}
