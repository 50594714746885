import { useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { css } from '@emotion/react';
import { GOOGLE_CLIENT_ID } from '@src/config';
import { GoogleLoginIcon } from '@src/components/icons/GoogleLogin';
import { DEFAULT_AFTER_LOGIN_PAGE, pageKeyToPathnameFn } from '@src/constants/page';
import { authApi } from '@src/apis/admin';
import { setLocalStorage } from '@src/utils/localStorage';

function LoginPage() {
  const navigate = useNavigate();

  const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('reloadAuthResponse' in res) {
      const promise = res.reloadAuthResponse();
      promise.then((data) => {
        login(data.id_token);
      });

      const userInfo = res.getBasicProfile();

      setLocalStorage('userName', userInfo.getName());
      setLocalStorage('userImg', userInfo.getImageUrl());
      setLocalStorage('userEmail', userInfo.getEmail());
    }
  };

  async function login(idToken: string) {
    try {
      const { data } = await authApi.loginAsync({ idToken });

      const { token } = data;
      setLocalStorage('token', token!);
      navigate(pageKeyToPathnameFn[DEFAULT_AFTER_LOGIN_PAGE]());
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div css={loginContainerStyle}>
      <div css={loginButtonContainerStyle}>
        <img src="/new_logo.png" alt="리얼월드 로고" style={{ width: '300px', marginBottom: '30px' }} />
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <button type="button" {...renderProps} css={googleLoginButtonStyle}>
              <GoogleLoginIcon />
              <div css={googleLoginButtonTextStyle}>구글 계정으로 계속하기</div>
            </button>
          )}
          onSuccess={onSuccess}
          cookiePolicy="single_host_origin"
          isSignedIn
        />
      </div>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          bottom: '30px',
          transform: 'translateX(-50%)',
          fontSize: '18px',
          color: '#bdbdbd'
        }}
      >
        리얼월드 관리자
      </div>
    </div>
  );
}

const loginContainerStyle = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #ffffff;
`;

const loginButtonContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const loginTitleStyle = css`
  margin-top: 16px;
  margin-bottom: 32px;
`;

const footerIconStyle = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
`;

const googleLoginButtonStyle = css`
  width: 200px;
  height: 40px;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
`;

const googleLoginButtonTextStyle = css`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;

  color: #000000;
`;

export default LoginPage;
