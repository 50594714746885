import { InboxOutlined } from '@ant-design/icons';
import { Input, Modal, ModalProps, Upload, message } from 'antd';
import React from 'react';
import { fileApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

const { Dragger } = Upload;

interface FileUploadModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  currentDirectory: string | undefined;
  refetch: () => unknown;
}

export default function FileUploadModal({ modalData, closeModal, currentDirectory, refetch }: FileUploadModalProps) {
  const accessToken = getLocalStorage('token');

  const [input, setInput] = React.useState('');
  const [file, setFile] = React.useState<File>();

  const handleSubmit = async () => {
    try {
      const { data } = await fileApi.uploadFile(input, currentDirectory, file, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (data.success) {
        closeModal();
        await refetch();
        message.success('파일 업로드에 성공했습니다.');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal {...modalData} onOk={handleSubmit} okText="확인" cancelText="닫기">
      <Input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="파일 설명"
        style={{ marginBottom: '16px' }}
      />
      <Dragger
        name="file"
        onRemove={() => setFile(undefined)}
        beforeUpload={(file) => {
          setFile(file);

          return false;
        }}
        maxCount={1}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p>클릭하거나 파일을 드래그해 업로드하세요.</p>
      </Dragger>
    </Modal>
  );
}
