import { PreferenceResponseModel } from '@uniquegood/realworld-admin-interface';
import { Checkbox, Form, Input, Modal, ModalProps } from 'antd';
import React from 'react';

interface ModifyPreferenceModalProps {
  modalData: ModalProps;
  onFinish: (values: PreferenceResponseModel) => unknown;
  originData: {
    id: string;
    name: string;
    description: string;
    imageUrl: string;
    isActivate: boolean;
  };
}

export default function ModifyPreferenceModal({ modalData, onFinish, originData }: ModifyPreferenceModalProps) {
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      ...originData
    });
  }, [form, originData]);

  return (
    <Modal {...modalData} title="취향 수정" onOk={() => form.submit()} okText="확인" cancelText="닫기" forceRender>
      <Form form={form} onFinish={onFinish} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <Form.Item label="취향 이름" name="name" rules={[{ required: true, message: '이름을 입력해주세요.' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="이미지 URL"
          name="imageUrl"
          rules={[{ required: true, message: '이미지 URL을 입력해주세요.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="설명" name="description">
          <Input />
        </Form.Item>
        <Form.Item label="활성화 여부" name="isActivate" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
}
