import { Divider, Layout, notification, Table, Tabs } from 'antd';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-components';
import { useSearchParams } from 'react-router-dom';
import {
  CommunityPostReportDetailResponseModel,
  CommunityReportListModel,
  ReportReason,
  ReportState
} from '@uniquegood/realworld-admin-interface/dist';
import DefaultLayout from '@src/components/DefaultLayout';
import Filter from '@src/components/UserCommunity/Filter';
import { communityReportApi } from '@src/apis/admin';
import ReportDetailModal from '@src/components/UserCommunity/ReportDetailModal';
import { YYYY_MM_DD } from '@src/constants/date';
import { ContentRequestType } from '@src/models/communityContentRequestType';
import { columns } from '@src/constants/communityTable';
import { getLocalStorage } from '@src/utils/localStorage';
import { communityReportReasonLabel } from '@src/constants/communityReason';
import { communityReportReason } from '@src/models/communityReportReason';
import { communityReportState } from '@src/models/communityReportState';
import { communityReportStateLabel } from '@src/constants/communityState';

const { Content } = Layout;

const defaultPaginationSet = {
  pageNumber: 1,
  reportPerPage: 50
};

const PAGE_TAKE = 20;

function CommunityReportList() {
  const [contentRequestType, setContentRequestType] = useState<ContentRequestType>('post');
  const [pageNumber, setPageNumber] = useState(defaultPaginationSet.pageNumber);
  const [totalReports, setTotalReports] = useState<number>();
  const [reportList, setReportList] = useState<CommunityReportListModel[]>([]);
  const [reportDetail, setReportDetail] = useState<CommunityPostReportDetailResponseModel>();
  const [selectedReportForModal, setSelectedReportForModal] = useState<
    | {
        reportId: string;
      }
    | undefined
  >();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getReportList(contentRequestType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, contentRequestType, pageNumber]);

  function handleChangeContentRequestType(contentRequestType: string) {
    (function resetSearchParams() {
      setSearchParams(new URLSearchParams());
    })();

    setContentRequestType(contentRequestType as ContentRequestType);
    setPageNumber(1);
  }

  async function getReportList(contentRequestType: 'post' | 'comment') {
    const parsedParams = Object.fromEntries(searchParams);
    if (Object.keys(parsedParams).length === 0) return;

    try {
      if (contentRequestType === 'post') {
        const {
          searchStartDate,
          searchEndDate,
          searchReason,
          searchStatus,
          searchWriter,
          searchContent,
          searchDescription
        } = parsedParams;

        const res = await communityReportApi.getPostReportList(
          'CreatedAt',
          searchStartDate,
          searchEndDate,
          searchStatus as ReportState,
          searchContent,
          searchDescription,
          searchReason as ReportReason,
          searchWriter,
          pageNumber, // page
          PAGE_TAKE, // take
          {
            headers: {
              Authorization: `Bearer ${getLocalStorage('token')}`
            }
          }
        );
        setTotalReports(Number(res.data.data?.count));
        if (res.data.data?.data) {
          setReportList(
            res.data.data?.data.map((item: CommunityReportListModel) => ({
              ...item,
              createdAtLabel: dayjs(item.createdAt).format(YYYY_MM_DD),
              reasonLabel: communityReportReasonLabel[item.reason as communityReportReason],
              isDeletedLabel: item.isDeleted ? 'O' : 'X',
              stateLabel: communityReportStateLabel[item.state as communityReportState]
            }))
          );
        }
      } else {
        const parsedParams = Object.fromEntries(searchParams);
        const {
          searchStartDate,
          searchEndDate,
          searchStatus,
          searchContent,
          searchDescription,
          searchReason,
          searchWriter
        } = parsedParams;

        const res = await communityReportApi.getCommentReportList(
          'CreatedAt',
          searchStartDate,
          searchEndDate,
          searchStatus as ReportState,
          searchContent,
          searchDescription,
          searchReason as ReportReason,
          searchWriter,
          pageNumber,
          PAGE_TAKE,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorage('token')}`
            }
          }
        );
        setTotalReports(Number(res.data.data?.count));
        if (res.data.data?.data) {
          setReportList(
            res.data.data?.data.map((item: CommunityReportListModel) => ({
              ...item,
              createdAtLabel: dayjs(item.createdAt).format(YYYY_MM_DD),
              reasonLabel: communityReportReasonLabel[item.reason as communityReportReason],
              isDeletedLabel: item.isDeleted ? 'O' : 'X',
              stateLabel: communityReportStateLabel[item.state as communityReportState]
            }))
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getReportDetail(contentRequestType: string, reportId: string) {
    try {
      if (contentRequestType === 'post') {
        const res = await communityReportApi.getPostReport(reportId, {
          headers: {
            Authorization: `Bearer ${getLocalStorage('token')}`
          }
        });

        setReportDetail(res.data.data);
      } else {
        const res = await communityReportApi.getCommentReport(reportId, {
          headers: {
            Authorization: `Bearer ${getLocalStorage('token')}`
          }
        });

        setReportDetail(res.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function patchReportProcess(reportId: string, process: ReportState) {
    try {
      if (contentRequestType === 'post') {
        const data = await communityReportApi.handlePostReport(
          reportId,
          {
            handlingType: process
          },
          {
            validateStatus: (status: number) => {
              return status < 500 || status !== 409;
            },
            headers: {
              Authorization: `Bearer ${getLocalStorage('token')}`
            }
          }
        );

        if (data.status === 409) {
          openFailNotification();
        }
      } else if (contentRequestType === 'comment') {
        const data = await communityReportApi.handleCommentReport(
          reportId,
          {
            handlingType: process
          },
          {
            validateStatus: (status: number) => {
              return status < 500 || status !== 409;
            },
            headers: {
              Authorization: `Bearer ${getLocalStorage('token')}`
            }
          }
        );

        if (data.status === 409) {
          openFailNotification();
        }
      }
    } catch (err) {
      console.log(err);
      openFailNotification();
    } finally {
      handleOnProcessed(process);
    }
  }

  function handleModalClose() {
    setSelectedReportForModal(undefined);
  }

  function handleReportDetailModal(reportId: string) {
    getReportDetail(contentRequestType, reportId);
    setSelectedReportForModal({ reportId });
  }

  function handleReportProcess(process: ReportState) {
    if (!selectedReportForModal) return;

    patchReportProcess(selectedReportForModal.reportId, process);
    setSelectedReportForModal(undefined);
  }

  const openSuccessNotification = (process: string) => {
    if (process === 'deleted') {
      notification.open({
        message: '삭제 처리 완료',
        description: '신고 내용이 삭제 처리되었습니다.',
        icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
        placement: 'bottomRight'
      });
    }

    if (process === 'rejected') {
      notification.open({
        message: '반려 처리 완료',
        description: '신고 내용이 반려 처리되었습니다.',
        icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
        placement: 'bottomRight'
      });
    }
  };

  const openFailNotification = () => {
    notification.open({
      message: '신고 처리 실패',
      description: '이미 처리되었거나 삭제된 신고 내용입니다.',
      icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
      placement: 'bottomRight'
    });
  };

  async function handleOnProcessed(process: string) {
    openSuccessNotification(process);
    getReportList(contentRequestType);
  }

  const tabItems: { label: string; key: ContentRequestType; children: JSX.Element }[] = [
    {
      label: '게시글',
      key: 'post',
      children: (
        <>
          <Filter />
          <Table<CommunityReportListModel>
            dataSource={reportList}
            columns={columns}
            rowKey={(record) => (typeof record.reportId === 'string' ? record.reportId : '')}
            pagination={{
              total: totalReports,
              pageSize: PAGE_TAKE,
              onChange: (page) => {
                setPageNumber(page);
              }
            }}
            scroll={{ y: 600 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (typeof record.reportId === 'string') {
                    handleReportDetailModal(record.reportId);
                  }
                }
              };
            }}
          />
        </>
      )
    },
    {
      label: '댓글﹒답글',
      key: 'comment',
      children: (
        <>
          <Filter />
          <Table<CommunityReportListModel>
            dataSource={reportList}
            columns={columns}
            rowKey={(record) => (record.reportId && typeof record.reportId === 'string' ? record.reportId : '')}
            pagination={{
              pageSizeOptions: [50, 100],
              defaultPageSize: 50,
              total: totalReports,
              pageSize: PAGE_TAKE,
              onChange: (page) => {
                setPageNumber(page);
              }
            }}
            scroll={{ y: 600 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (record.reportId) {
                    handleReportDetailModal(record.reportId);
                  }
                }
              };
            }}
          />
        </>
      )
    }
  ];

  return (
    <DefaultLayout>
      <Layout>
        <PageHeader
          title="유저 커뮤니티 관리"
          subTitle="리얼월드 커뮤니티의 신고된 글과 댓글을 관리합니다"
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
          <Tabs type="card" items={tabItems} onTabClick={handleChangeContentRequestType} />
        </Content>
      </Layout>

      {/* 신고 상세 모달 */}
      {reportDetail && (
        <ReportDetailModal
          reportDetail={reportDetail}
          isModalOpen={Boolean(selectedReportForModal)}
          handleModalClose={handleModalClose}
          handleReportProcess={handleReportProcess}
        />
      )}
    </DefaultLayout>
  );
}

export default CommunityReportList;
