import { QuestionCircleFilled, UploadOutlined } from '@ant-design/icons';
import { CommunityBannerType, CreateCommunityBannerRequestModel } from '@uniquegood/realworld-admin-interface';
import { Button, Form, Input, Modal, ModalProps, Select, Tooltip, Upload, message } from 'antd';
import React from 'react';
import { useAsync } from 'react-use';
import { getLocalStorage } from '@src/utils/localStorage';
import { communityBannerTypeToLabel } from '@src/constants/banner';
import { communityBannerApi, fileApi, projectApi } from '@src/apis/admin';

interface CreateBannerModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  refetch: () => unknown;
}

export default function CreateBannerModal({ modalData, closeModal, refetch }: CreateBannerModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const [selectedBannerType, setSelectedBannerType] = React.useState<CommunityBannerType | undefined>();

  const projects = useAsync(async () => {
    try {
      const { data: preData } = await projectApi.getProjects(undefined, undefined, undefined, 1, 1, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const { data } = await projectApi.getProjects(undefined, undefined, undefined, 1, preData.data.count, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return data.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);

  const handleSubmit = async (
    values: CreateCommunityBannerRequestModel & {
      image: { file: File };
      'noticeRequest.title': string;
      'noticeRequest.content': string;
      'noticeRequest.imageIds': { fileList: FileList };
      'noticeRequest.linkedProjectIds': string[];
      linkUrl: string;
    }
  ) => {
    try {
      if (!selectedBannerType) return;

      const {
        data: {
          data: { url }
        }
      } = await fileApi.uploadFile(undefined, undefined, values.image.file, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      // TODO: 이미지 여러 개 업로드 기능 추가

      const imageIds =
        selectedBannerType === CommunityBannerType.Notice &&
        (values['noticeRequest.imageIds'] !== undefined ||
          (values['noticeRequest.imageIds'] as { fileList: FileList })?.fileList.length > 0)
          ? await Promise.all(
              Array.from(values['noticeRequest.imageIds']?.fileList).map(async (file) => {
                const fileData = file as File & { originFileObj: File };
                const { data } = await fileApi.uploadFile(undefined, undefined, fileData.originFileObj, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`
                  }
                });
                return data.data.id || '';
              })
            )
          : null;

      const { data } = await communityBannerApi.createBanner(
        {
          ...values,
          imageUrl: url || '',
          bannerType: selectedBannerType,
          noticeRequest:
            selectedBannerType === CommunityBannerType.Notice
              ? {
                  title: values['noticeRequest.title'],
                  content: values['noticeRequest.content'],
                  imageIds: imageIds || null,
                  linkedProjectIds: values['noticeRequest.linkedProjectIds']
                }
              : undefined
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        message.success('배너를 생성했습니다.');
        closeModal();
        await refetch();
      }
    } catch (e) {
      console.error(e);
      message.error('배너 생성에 실패했습니다.');
    }
  };

  form.setFieldValue('bannerType', selectedBannerType);

  return (
    <Modal
      {...modalData}
      onOk={form.submit}
      okText="확인"
      cancelText="닫기"
      title={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          배너 생성
          <Tooltip title="배너 운영/제작 가이드를 확인하시려면 클릭하세요!">
            <QuestionCircleFilled
              onClick={() =>
                window.open('https://www.notion.so/uniquegoodcompany/83dff5eaf6884d7790b4ecafb5dd0dce?pvs=4', '_blank')
              }
              style={{
                width: '16px',
                height: '16px',
                marginLeft: '4px',
                color: '#444',
                cursor: 'pointer'
              }}
            />
          </Tooltip>
        </div>
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        preserve={false}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        validateMessages={{ required: '필수로 입력해주세요.' }}
      >
        <Form.Item name="bannerType" label="배너 타입" rules={[{ required: true }]}>
          <Select
            placeholder="배너 타입을 선택해주세요."
            options={Object.values(CommunityBannerType).map((bannerType) => ({
              value: bannerType,
              label: communityBannerTypeToLabel[bannerType]
            }))}
            onSelect={(value) => setSelectedBannerType(value)}
          />
        </Form.Item>
        <Form.Item name="title" label="제목" rules={[{ required: true }]}>
          <Input.TextArea autoSize={{ minRows: 2 }} placeholder="제목을 입력해주세요." />
        </Form.Item>
        <Form.Item name="subTitle" label="부제목">
          <Input placeholder="부제목을 입력해주세요." />
        </Form.Item>
        {selectedBannerType === CommunityBannerType.Image && (
          <Form.Item name="linkUrl" label="클릭 시 이동할 주소" rules={[{ required: true }]}>
            <Input placeholder="클릭 시 이동할 주소를 입력해주세요." />
          </Form.Item>
        )}
        <Form.Item name="image" label="이미지" rules={[{ required: true }]}>
          <Upload maxCount={1} beforeUpload={() => false} listType="picture">
            <Button>
              <UploadOutlined />
              이미지 업로드
            </Button>
          </Upload>
        </Form.Item>
        {selectedBannerType === CommunityBannerType.Notice && (
          <>
            <Form.Item name="noticeRequest.title" label="공지글 제목" rules={[{ required: true }]}>
              <Input placeholder="공지글 제목을 입력해주세요." />
            </Form.Item>
            <Form.Item name="noticeRequest.content" label="공지글 내용">
              <Input.TextArea autoSize={{ minRows: 3 }} placeholder="공지글 내용을 입력해주세요." />
            </Form.Item>
            <Form.Item name="noticeRequest.imageIds" label="공지 이미지">
              <Upload beforeUpload={() => false} listType="picture">
                <Button>
                  <UploadOutlined />
                  이미지 업로드
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item name="noticeRequest.linkedProjectIds" label="프로젝트">
              <Select
                mode="multiple"
                placeholder="프로젝트를 선택해주세요."
                options={projects.value?.data?.map((project) => ({
                  label: project.name,
                  value: project.id
                }))}
                loading={projects.loading}
                filterOption={(input, option) => {
                  if (!option) return false;

                  return option.label?.includes(input) || false;
                }}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}
