import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Space, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAsyncFn } from 'react-use';
import React from 'react';
import { MobileAppSettingModel } from '@uniquegood/realworld-admin-interface/dist';
import { PlusCircleOutlined } from '@ant-design/icons';
import DefaultLayout from '@src/components/DefaultLayout';
import { miscApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import useModalState from '@src/hooks/useModalState';
import CreateSettingModal from './CreateSettingModal';
import { SettingItem } from './SettingItem';

const { Title } = Typography;

export default function SystemSettings() {
  const accessToken = getLocalStorage('token');

  const [settingData, setSettingData] = React.useState<MobileAppSettingModel>();
  const [os, setOs] = React.useState<'ios' | 'android'>();

  const initialSettingData = React.useRef<MobileAppSettingModel>();
  const hasDifference = JSON.stringify(initialSettingData.current) !== JSON.stringify(settingData);

  const { openModal, closeModal, modal } = useModalState();

  const [settingDataApi, fetchSettingDataApi] = useAsyncFn(async () => {
    const { data } = await miscApi.getMobileAppSettings({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    initialSettingData.current = data.data;
    setSettingData(data.data);

    return data;
  }, []);

  const handleClick = (osValue: typeof os) => {
    setOs(osValue);
    openModal({
      title: '설정 추가',
      transitionName: ''
    });
  };

  const handleSaveClick = async () => {
    try {
      await miscApi.upsertMobileAppSettings(settingData, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      message.success('저장에 성공했습니다.');
      fetchSettingDataApi();
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    fetchSettingDataApi();
  }, [fetchSettingDataApi]);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title="시스템 관리"
          subTitle="시스템 관련 설정 할 수 있습니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />

        <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Button type="primary" disabled={!hasDifference} onClick={handleSaveClick}>
              저장
            </Button>
          </div>
          <Space>
            <Title level={3} style={{ margin: 0 }}>
              IOS
            </Title>
            <Button type="dashed" shape="circle" icon={<PlusCircleOutlined />} onClick={() => handleClick('ios')} />
          </Space>
          {!settingDataApi.loading && settingData?.ios && (
            <div style={{ marginBottom: '16px' }}>
              {Object.entries(settingData.ios).map((item) => (
                <SettingItem
                  key={item[0]}
                  item={item}
                  osType="ios"
                  settingData={settingData}
                  setSettingData={setSettingData}
                />
              ))}
            </div>
          )}
          <Space>
            <Title level={3} style={{ margin: 0 }}>
              Android
            </Title>
            <Button type="dashed" shape="circle" icon={<PlusCircleOutlined />} onClick={() => handleClick('android')} />
          </Space>

          {!settingDataApi.loading && settingData?.android && (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              {Object.entries(settingData.android).map((item) => (
                <SettingItem
                  key={item[0]}
                  item={item}
                  osType="android"
                  settingData={settingData}
                  setSettingData={setSettingData}
                />
              ))}
            </div>
          )}
        </Content>
      </DefaultLayout>
      <CreateSettingModal
        modal={modal}
        closeModal={closeModal}
        currentData={settingData || {}}
        setSettingData={setSettingData}
        os={os}
      />
    </>
  );
}
