import { Col, Input, Modal, ModalProps, Row, notification } from 'antd';
import React from 'react';
import { projectApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface ChangeChannelModalProps {
  modalData: ModalProps;
  selectedProjectId: string;
  closeModal: () => unknown;
}

export default function ChangeChannelModal({ modalData, selectedProjectId, closeModal }: ChangeChannelModalProps) {
  const accessToken = getLocalStorage('token');
  const [channelId, setChannelId] = React.useState('');

  const handleOkClick = async () => {
    try {
      const { data } = await projectApi.updateProjectApplication(
        selectedProjectId,
        {
          applicationId: channelId
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        notification.success({
          message: '채널 변경에 성공했습니다.'
        });
        closeModal();
      }
    } catch (e) {
      console.error(e);
      notification.error({
        message: '채널 변경에 실패했습니다. 다시 시도해주세요.'
      });
    }
  };

  return (
    <Modal {...modalData} title="채널 변경" transitionName="" okText="확인" cancelText="닫기" onOk={handleOkClick}>
      <Row gutter={[0, 8]}>
        <Col>
          이동할 채널 ID를 입력해주세요. <br /> 채널 ID는 <strong>크리에이터 관리 &gt; 채널 관리</strong>에서 확인하실
          수 있습니다.
        </Col>
        <Col flex={1}>
          <Input onChange={(e) => setChannelId(e.target.value)} placeholder="이동할 채널 ID" />
        </Col>
      </Row>
    </Modal>
  );
}
