import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Input, Space, Table, Upload, notification } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Excel, { Buffer, CellValue } from 'exceljs';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import DefaultLayout from '@src/components/DefaultLayout';
import { pointApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { uuidv4 } from '@src/utils/uuid';

interface listType {
  key: string;
  id: string;
  point: string;
  reason: string;
}

export default function PointProvision() {
  const [list, setList] = React.useState<listType[]>([]);
  const [input, setInput] = React.useState({
    id: '',
    point: '',
    reason: ''
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const accessToken = getLocalStorage('token');

  const isInputFilled = input.id !== '' && input.point !== '' && input.reason !== '';
  const isTableFilled = list.length > 0;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setInput({
      ...input,
      [id]: value
    });
  };

  const handleAdd = () => {
    setList([
      ...list,
      {
        key: uuidv4(),
        id: input.id,
        point: input.point,
        reason: input.reason
      }
    ]);

    setInput({
      id: '',
      point: '',
      reason: ''
    });
  };

  const handleSubmit = async () => {
    try {
      if (list.length === 1) {
        const { id, point, reason } = list[0];
        setIsSubmitting(true);

        await pointApi.chargeUserPoint(
          {
            userId: id,
            freePoint: Number(point),
            chargeReason: reason
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        setIsSubmitting(false);

        notification.success({
          message: '츄로 지급 완료!'
        });
      } else if (list.length > 1) {
        setIsSubmitting(true);
        const { data } = await pointApi.chargesUsersPoints(
          list.map((item) => {
            return {
              userId: item.id,
              freePoint: Number(item.point),
              chargeReason: item.reason
            };
          }),
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        setIsSubmitting(false);

        notification.success({
          message: '츄로 지급 완료!'
        });
      }
    } catch (e) {
      console.error(e);

      setIsSubmitting(false);

      notification.error({
        message: '오류가 발생해 츄로 지급을 완료하지 못했습니다. 잠시 후 다시 시도해주세요.'
      });
    } finally {
      setList([]);
    }
  };

  const handleBeforeUpload = (file: File) => {
    const wb = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;

      const temp: listType[] = [];

      wb.xlsx.load(buffer as Buffer).then((workbook) => {
        workbook.eachSheet((sheet, id) => {
          sheet.eachRow((row, rowIndex) => {
            const value = row.values as CellValue[];

            if (rowIndex > 1) {
              temp.push({
                key: uuidv4(),
                id: value[1] as string,
                point: value[2] as string,
                reason: value[3] as string
              });
            }
          });
        });

        setList([...list, ...temp]);
      });
    };

    return false;
  };

  const columns: ColumnsType<listType> = [
    {
      title: '플레이어 ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '지급할 츄로',
      dataIndex: 'point',
      key: 'point'
    },
    {
      title: '지급 내용',
      dataIndex: 'reason',
      key: 'reason'
    },
    {
      title: '동작',
      render: (_, record) => (
        <Button onClick={() => setList(list.filter((item) => item.key !== record.key))} danger>
          삭제
        </Button>
      )
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader title="츄로 지급" subTitle="사용자에게 츄로를 지급합니다." style={{ margin: '16px 20px 0 20px' }} />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Space direction="vertical" style={{ width: '100%', marginBottom: '16px' }}>
          <Space direction="horizontal">
            <Input id="id" placeholder="플레이어 ID" value={input.id} onChange={handleChange} />
            <Input id="point" placeholder="지급할 츄로" value={input.point} onChange={handleChange} />
            <Input id="reason" placeholder="지급 내용" value={input.reason} onChange={handleChange} />
            <Button type="default" onClick={handleAdd} disabled={!isInputFilled}>
              지급 사용자 추가
            </Button>
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Upload beforeUpload={handleBeforeUpload} showUploadList={false}>
              <Button>엑셀로 추가하기</Button>
            </Upload>
            <Button type="primary" onClick={handleSubmit} disabled={!isTableFilled} loading={isSubmitting}>
              츄로 지급
            </Button>
          </Space>
        </Space>
        <Table dataSource={list} columns={columns} />
      </Content>
    </DefaultLayout>
  );
}
