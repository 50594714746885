import { Form, Input, Modal, ModalProps, message } from 'antd';
import React from 'react';
import { fileApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface CreateOrModifyMetadataModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  fileId: string;
  metadata: string;
  refetch: () => unknown;
}

export default function CreateOrModifyMetadataModal({
  modalData,
  closeModal,
  fileId,
  metadata,
  refetch
}: CreateOrModifyMetadataModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const handleSubmit = async (values: { metadata: string }) => {
    try {
      const { data } = await fileApi.editFile(fileId, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (data.success) {
        closeModal();
        await refetch();
        message.success('파일 데이터 수정에 성공했습니다.');
      } else {
        message.error('파일 데이터 수정에 실패했습니다.');
      }
    } catch (e) {
      console.error(e);
      message.error('파일 데이터 수정에 실패했습니다.');
    }
  };

  React.useEffect(() => {
    form.setFieldsValue({
      metadata
    });
  }, [metadata]);

  return (
    <Modal {...modalData} onOk={form.submit} title="메타데이터 편집" okText="확인" cancelText="닫기">
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="metadata">
          <Input placeholder="메타 데이터를 입력해주세요." />
        </Form.Item>
      </Form>
    </Modal>
  );
}
