import { Badge, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QuizTemplate } from '@src/models/quizTemplate';

const QUIZ_TEMPLATE_TYPE = [
  { key: 'Common', value: '일반', color: '' },
  { key: 'Tutorial', value: '튜토리얼', color: '' }
];

export const QUIZ_TEMPLATE_COLUMNS: ColumnsType<QuizTemplate> = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left'
  },
  {
    title: '템플릿 명',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '타입',
    dataIndex: 'type',
    key: 'type',
    render: (type) => {
      return <Tag>{QUIZ_TEMPLATE_TYPE.find((item) => item.key === type)?.value}</Tag>;
    }
  },
  {
    title: '생성 상태',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      return (
        <Tag color={status === 'wait' ? 'grey' : 'green-inverse'} key={status}>
          {status}
        </Tag>
      );
    }
  },
  {
    title: '활성화 여부',
    dataIndex: 'isEnable',
    key: 'isEnable',
    render: (value) => {
      return <div>{value ? <Badge dot color="green" text="활성화" /> : <Badge dot color="red" text="비활성화" />}</div>;
    }
  }
];
