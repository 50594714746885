import {
  ProjectCommentResponseModel,
  ProjectLite,
  UserRecommendedProjectResponseModel
} from '@uniquegood/realworld-admin-interface';
import { ColorPicker, Form, Modal, ModalProps, Select } from 'antd';
import { Color } from 'antd/lib/color-picker';
import React from 'react';
import CommentTable from './CommentTable';

type CreateModalProps = {
  modalData: ModalProps;
  onSubmit: (values: UserRecommendedProjectResponseModel) => unknown;
  totalProjects: ProjectLite[];
};

type FormData = {
  projectId: string;
  reviewComment: ProjectCommentResponseModel;
  backgroundColorCode: Color;
};

export default function CreateModal({ modalData, totalProjects, onSubmit }: CreateModalProps) {
  const [form] = Form.useForm();

  const [selectedProjectId, setSelectedProjectId] = React.useState('');

  const handleSubmit = async (values: FormData) => {
    try {
      onSubmit({
        ...values,
        order: 0,
        project: totalProjects.find((project) => project.id === selectedProjectId) as ProjectLite,
        backgroundColorCode: values.backgroundColorCode.toHexString()
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleCommentSelect = (comment: ProjectCommentResponseModel) => {
    form.setFieldValue('reviewComment', comment);
  };

  return (
    <Modal {...modalData} title="키워드 추가" onOk={form.submit} okText="확인" cancelText="닫기">
      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        preserve={false}
        validateMessages={{ required: '필수로 입력해주세요.' }}
      >
        <Form.Item name="projectId" label="프로젝트" rules={[{ required: true }]}>
          <Select
            placeholder="프로젝트를 선택해주세요."
            onChange={setSelectedProjectId}
            options={totalProjects.map((project) => ({
              label: project.name,
              value: project.id
            }))}
            showSearch
            filterOption={(input, option) => {
              if (!option) return false;

              return option.label?.includes(input) || false;
            }}
          />
        </Form.Item>
        {selectedProjectId && (
          <Form.Item rules={[{ required: true }]}>
            <CommentTable projectId={selectedProjectId} onCommentSelect={handleCommentSelect} />
          </Form.Item>
        )}
        <Form.Item name="backgroundColorCode" label="배경 색상" rules={[{ required: true }]}>
          <ColorPicker format="hex" showText />
        </Form.Item>
        <Form.Item name="reviewComment" hidden />
      </Form>
    </Modal>
  );
}
