import { ExclamationCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Col, Form, Input, InputNumber, Modal, Popconfirm, Radio, Row, Switch } from 'antd';
import React from 'react';
import Button from '@src/components/Button';

export interface GiftCardListPageEditModalOnConfirmProps {
  keyword?: string;
  price?: number;
  active?: boolean;
}

interface GiftCardListPageEditModalProps {
  isOpen: boolean;
  onCloseModal: () => unknown;
  selectedCount: number;
  onConfirm: (nextValue: GiftCardListPageEditModalOnConfirmProps) => unknown;
  isLoading?: boolean;
}

function GiftCardListPageEditModal({
  isOpen,
  onCloseModal,
  selectedCount,
  onConfirm,
  isLoading
}: GiftCardListPageEditModalProps) {
  const [form] = Form.useForm();

  const [isEnableEditKeyword, setIsEnableEditKeyword] = React.useState(false);
  const [isEnableEditPrice, setIsEnableEditPrice] = React.useState(false);
  const [isEnableEditActive, setIsEnableEditActive] = React.useState(false);

  // 껏다 켤때마다 초기화
  React.useEffect(() => {
    if (isOpen) form.resetFields();
  }, [isOpen]);

  const handleClickConfirm = React.useCallback(() => {
    const temp: GiftCardListPageEditModalOnConfirmProps = {};
    if (isEnableEditKeyword) temp.keyword = form.getFieldValue('editKeyword');
    if (isEnableEditPrice) temp.price = form.getFieldValue('editPrice');
    if (isEnableEditActive) temp.active = form.getFieldValue('editActive') === 'true';

    onConfirm(temp);
  }, [form, isEnableEditActive, isEnableEditKeyword, isEnableEditPrice, onConfirm]);

  const handleCloseModal = React.useCallback(() => {
    if (isLoading) return;
    onCloseModal();
  }, [isLoading, onCloseModal]);

  return (
    <Modal
      width="50%"
      open={isOpen}
      title="기프트카드 일괄 수정"
      onCancel={handleCloseModal}
      footer={[
        <Popconfirm
          title="정말 수정 하시겠습니까?"
          okText="네"
          cancelText="아니오"
          placement="bottom"
          icon={<ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />}
          onConfirm={handleClickConfirm}
          okButtonProps={{ danger: true, disabled: isLoading }}
          cancelButtonProps={{ disabled: isLoading }}
        >
          <Button
            key="edit"
            type="primary"
            value="edit"
            disabled={isLoading || !(isEnableEditKeyword || isEnableEditPrice || isEnableEditActive)}
          >
            수정하기
          </Button>
        </Popconfirm>
      ]}
    >
      <div css={modalTitleStyle}>
        <span css={countAccentStyle}>{selectedCount}</span>건의 기프트카드 정보를 일괄 변경하시겠습니까?
      </div>
      <Form layout="inline" form={form}>
        <Row gutter={24} css={rowStyle}>
          <Col lg={11} span={24} css={labelColumnStyle}>
            <Form.Item label="키워드 수정하기" name="isEnableEditKeyword" valuePropName="checked">
              <Switch onChange={setIsEnableEditKeyword} />
            </Form.Item>
          </Col>
          <Col lg={2} span={0} />
          <Col lg={11} span={24} css={valueColumnStyle}>
            <Form.Item noStyle name="editKeyword">
              <Input disabled={!isEnableEditKeyword} />
            </Form.Item>
          </Col>
          <Col lg={11} span={24} css={labelColumnStyle}>
            <Form.Item label="발급단가 수정하기" name="isEnableEditPrice" valuePropName="checked">
              <Switch onChange={setIsEnableEditPrice} />
            </Form.Item>
          </Col>
          <Col lg={2} span={0} />
          <Col lg={11} span={24} css={valueColumnStyle}>
            <Form.Item noStyle name="editPrice">
              <InputNumber disabled={!isEnableEditPrice} />
            </Form.Item>
          </Col>
          <Col lg={11} span={24} css={labelColumnStyle}>
            <Form.Item label="활성/비활성 수정하기" name="isEnableEditActive" valuePropName="checked">
              <Switch onChange={setIsEnableEditActive} />
            </Form.Item>
          </Col>
          <Col lg={2} span={0} />
          <Col lg={11} span={24} css={valueColumnStyle}>
            <Form.Item noStyle name="editActive" initialValue="true">
              <Radio.Group disabled={!isEnableEditActive}>
                <Radio value="true">활성화</Radio>
                <Radio value="false">비활성화</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const modalTitleStyle = css`
  font-size: 1.1em;
  font-weight: bold;
`;

const countAccentStyle = css`
  font-size: 1.3em;
  color: red;
`;

const rowStyle = css`
  width: 100%;
  row-gap: 8px;
  margin-top: 16px;
`;

const valueColumnStyle = css`
  display: flex;
  align-items: center;
`;

const labelColumnStyle = css`
  display: flex;
  justify-content: flex-end;
`;

export default GiftCardListPageEditModal;
