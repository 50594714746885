import { useEffect, useRef, useState } from 'react';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, FloatButton, Space } from 'antd';
import { Banner as BannerType } from '@uniquegood/realworld-admin-interface';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import produce, { castDraft } from 'immer';

import { uuidv4 } from '@src/utils/uuid';
import Banner from './Banner';

interface ExtendedBannerType extends BannerType {
  id: string;
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#e6f4ff' : 'none',
  padding: '5px 10px',
  display: 'grid'
});

interface Props {
  data: BannerType[];
  onSave: (bannerItems: BannerType[]) => void;
  isSaving: boolean;
}

function TopBanners({ data, onSave, isSaving }: Props) {
  const [bannerItems, setBannerItems] = useState<ExtendedBannerType[]>([]);
  const tempBannerItems = useRef<ExtendedBannerType[]>([]);

  useEffect(() => {
    const tempData = data.map((banner) => {
      return { ...banner, id: uuidv4() };
    });
    setBannerItems(tempData);
    tempBannerItems.current = tempData;
  }, [data]);

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const tempData = produce(tempBannerItems.current, (draftState) => {
      const [reorderedItem] = draftState.splice(result.source.index, 1);
      draftState.splice(result.destination.index, 0, reorderedItem);
    });

    setBannerItems(tempData);
    tempBannerItems.current = tempData;
  }

  function onBannerAdd() {
    const tempData = produce(tempBannerItems.current, (draftState) => {
      draftState.push(castDraft({ id: uuidv4(), name: '새 배너', imageUrl: '', linkUrl: '' }));
    });

    setBannerItems(tempData);
    tempBannerItems.current = tempData;
  }

  function onBannerRemove(index: number) {
    const tempData = produce(tempBannerItems.current, (draftState) => {
      draftState.splice(index, 1);
    });

    setBannerItems(tempData);
    tempBannerItems.current = tempData;
  }

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Button onClick={onBannerAdd} icon={<PlusOutlined />}>
          배너 추가
        </Button>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {bannerItems.map(({ id, name, imageUrl, linkUrl }, index) => {
                  return (
                    <Draggable key={id} draggableId={String(id)} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{ margin: '5px 0', ...provided.draggableProps.style }}
                        >
                          <Banner
                            data={{ name, imageUrl, linkUrl }}
                            setData={({ key, value }) => {
                              tempBannerItems.current = produce(tempBannerItems.current, (draftState) => {
                                draftState.splice(index, 1, castDraft({ ...draftState[index], [key]: value }));
                              });
                            }}
                            onClickRemove={() => onBannerRemove(index)}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Space>
      <FloatButton
        icon={<SaveOutlined />}
        description="저장"
        type="primary"
        shape="square"
        style={{ right: 88, width: '60px', height: '48px' }}
        onClick={() => {
          if (!isSaving) onSave(tempBannerItems.current);
        }}
      />
    </>
  );
}

export default TopBanners;
