import { Banner, SectionDisplayType } from '@uniquegood/realworld-admin-interface';
import { AxiosRequestConfig } from 'axios';
import { Section } from '@src/models/mainPage';
import { mainPageApi } from '..';

interface adminApiGetMainPageProps {
  id: string;
}

export const adminApiGetMainPage = async ({ id }: adminApiGetMainPageProps, options?: AxiosRequestConfig) => {
  const { data } = await mainPageApi.getMainPage(id, options);

  return Object.entries(data.items!).reduce((obj, [localization, mainPageItem]) => {
    const temp = { ...obj };
    if (mainPageItem)
      temp[localization] = mainPageItem.reduce((arr, cur) => {
        const temp: (Section | Banner)[] = [...arr];
        if (cur.name) {
          if (cur.displayType !== undefined && cur.projects !== undefined && cur.projects !== null) {
            // FIXME: 서버에서 0, 1, 2로 오고있음
            const displayType = (() => {
              if (typeof cur.displayType === 'string') return cur.displayType;

              if (cur.displayType === 0) return SectionDisplayType.HorizontalScrollList;
              if (cur.displayType === 1) return SectionDisplayType.HorizontalTileList;
              if (cur.displayType === 2) return SectionDisplayType.VerticalList;

              throw Error('displayType Deserialize Error!!');
            })();
            temp.push({ name: cur.name, displayType, projects: cur.projects });
          }
          if (
            cur.imageUrl !== undefined &&
            cur.imageUrl !== null &&
            cur.linkUrl !== undefined &&
            cur.linkUrl !== null
          ) {
            temp.push({ name: cur.name, imageUrl: cur.imageUrl, linkUrl: cur.linkUrl });
          }
        }
        return temp;
      }, [] as (Section | Banner)[]);

    return temp;
  }, {} as Record<string, (Section | Banner)[]>);
};
