import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, ModalProps, Upload, message } from 'antd';
import { fileApi, headerThumbnailApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

type CreateModalProps = {
  modalData: ModalProps;
  closeModal: () => unknown;
  groupId: string;
  refetch: () => Promise<unknown>;
};

type FormData = {
  titleImageUrl: {
    file: File;
    fileList: FileList[];
  };
  backgroundImageUrl: {
    file: File;
    fileList: FileList[];
  };
  subTitle: string;
  linkUrl: string;
  webLinkUrl: string;
};

export default function CreateModal({ modalData, closeModal, groupId, refetch }: CreateModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const handleSubmit = async (values: FormData) => {
    try {
      const {
        data: {
          data: { url: titleImageUrl }
        }
      } = values.titleImageUrl
        ? await fileApi.uploadFile(undefined, undefined, values.titleImageUrl.file, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
        : { data: { data: { url: undefined } } };
      const {
        data: {
          data: { url: backgroundImageUrl }
        }
      } = await fileApi.uploadFile(undefined, undefined, values.backgroundImageUrl.file, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const { data } = await headerThumbnailApi.createHeaderThumbnail(
        groupId,
        {
          ...values,
          subtitle: values.subTitle,
          titleImageUrl: titleImageUrl || '',
          backgroundImageUrl: backgroundImageUrl || ''
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        message.success('썸네일을 생성했습니다.');

        await refetch();

        closeModal();
      }
    } catch (e) {
      console.error(e);
      message.error('썸네일 생성에 실패했습니다.');
    }
  };

  return (
    <Modal {...modalData} title="메인 썸네일 생성" okText="확인" cancelText="닫기" onOk={form.submit}>
      <Form
        form={form}
        onFinish={handleSubmit}
        preserve={false}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        validateMessages={{ required: '필수 입력 항목입니다.' }}
      >
        <Form.Item name="backgroundImageUrl" label="배경 이미지" rules={[{ required: true }]}>
          <Upload maxCount={1} beforeUpload={() => false} listType="picture">
            <Button icon={<UploadOutlined />}>이미지 업로드</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="linkUrl" label="앱 링크 URL" rules={[{ required: true }]}>
          <Input placeholder="클릭 시 이동할 앱 주소를 입력해주세요." />
        </Form.Item>
        <Form.Item name="webLinkUrl" label="웹 링크 URL" rules={[{ required: true }]}>
          <Input placeholder="클릭 시 이동할 주소를 입력해주세요." />
        </Form.Item>
        <Form.Item name="titleImageUrl" label="제목 이미지">
          <Upload maxCount={1} beforeUpload={() => false} listType="picture">
            <Button icon={<UploadOutlined />}>이미지 업로드</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="subTitle" label="부제목">
          <Input placeholder="부제목을 입력해주세요." />
        </Form.Item>
      </Form>
    </Modal>
  );
}
