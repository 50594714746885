import { PageHeader } from '@ant-design/pro-components';
import { Divider, Input, Pagination, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Modal from 'antd/es/modal/Modal';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetQuizTemplates } from '@src/apis/black-story/queries';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { GetQuizTemplateParams, QuizTemplate } from '@src/models/quizTemplate';
import { getLocalStorage } from '@src/utils/localStorage';

import { QUIZ_TEMPLATE_COLUMNS } from './constants';

const { Search } = Input;

export default function () {
  const navigate = useNavigate();
  const queryData = new URLSearchParams(window.location.search);

  const accessToken = getLocalStorage('token');

  const { openModal, closeModal, modal } = useModalState();
  const { openModal: openRemoveModal, closeModal: closeRemoveModal, modal: removeModal } = useModalState();

  const [totalCount, setTotalCount] = React.useState<number | undefined>(0);
  const [quizTemplates, setQuizTemplates] = React.useState<QuizTemplate[]>([]);

  const [query, setQuery] = useState<GetQuizTemplateParams>({ limit: 10, page: 1 });
  const { data: quizTemplatesData, isLoading } = useGetQuizTemplates(query);

  useEffect(() => {
    if (quizTemplatesData && !isLoading) {
      setTotalCount(quizTemplatesData.data.meta?.pagination?.totalCount);
      setQuizTemplates(quizTemplatesData.data.data ?? []);
    }
  }, [isLoading, quizTemplatesData]);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title="바거스 퀴즈 템플릿 관리"
          subTitle="바거스 퀴즈 템플릿을 관리합니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />

        <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
          <Table
            tableLayout="auto"
            columns={QUIZ_TEMPLATE_COLUMNS}
            dataSource={quizTemplates}
            pagination={{
              position: ['bottomRight'],
              pageSize: query.limit,
              showSizeChanger: false,
              total: totalCount ?? 0,
              current: query.page ?? 1,
              onChange: (page) => setQuery({ ...query, page })
            }}
            scroll={{ x: 'max-content', y: 600, scrollToFirstRowOnChange: true }}
          >
            <Pagination />
          </Table>
        </Content>
      </DefaultLayout>
      <Modal width="calc(100vw - 48px)" cancelText="닫기" {...modal} />
      <Modal {...removeModal} />
    </>
  );
}
