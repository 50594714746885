import { assign, object, string, literal, validate, optional } from 'superstruct';

const adminErrorStruct = object({
  errorTitle: optional(string()),
  message: string()
});
export function parseAdminError(data: unknown) {
  return validate(data, adminErrorStruct);
}

const adminLoginRequiredErrorStruct = assign(adminErrorStruct, object({ errorTitle: literal('LoginRequired') }));
export function parseAdminLoginRequiredError(data: unknown) {
  return validate(data, adminLoginRequiredErrorStruct);
}

const adminExpiredTokenErrorStruct = assign(adminErrorStruct, object({ errorTitle: literal('ExpiredToken') }));
export function parseAdminExpiredTokenError(data: unknown) {
  return validate(data, adminExpiredTokenErrorStruct);
}

const adminInvalidTokenErrorStruct = assign(adminErrorStruct, object({ errorTitle: literal('InvalidToken') }));
export function parseAdminInvalidTokenError(data: unknown) {
  return validate(data, adminInvalidTokenErrorStruct);
}

const adminNotFoundItemErrorStruct = assign(adminErrorStruct, object({ errorTitle: literal('NotFoundItem') }));
export function parseAdminNotFoundItemError(data: unknown) {
  return validate(data, adminNotFoundItemErrorStruct);
}
